import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, AfterContentInit, ElementRef, Renderer2, ViewChildren, QueryList, ChangeDetectorRef, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Validator, NgForm } from '@angular/forms';
import { Subscription, Observable, Subject } from 'rxjs';
import { AppStore } from 'src/app/shared/states/app.reducer';
import { Store } from '@ngrx/store';
import * as fromValidator from '../../../shared/states/validator/validator.reducer';
import { map, tap, take, skipWhile, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';
import { TableData } from 'src/app/shared/models/validator.model';
import { environment } from 'src/environments/environment';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ValidatorModalComponent } from '../validator-modal/validator-modal.component';





@Component({
  selector: 'app-validator-list',
  templateUrl: './validator-list.component.html',
  styleUrls: ['./validator-list.component.scss'],
})
export class ValidatorListComponent implements OnInit, OnDestroy {

  @ViewChildren('tableRows', {read: ElementRef}) rowsContainer: QueryList<ElementRef>;
  @ViewChild('table', {static: false}) table: ElementRef;


  validators: TableData[];
  currentStakedList: TableData[];
  currentERList: TableData[];
  currentFeeList: TableData[];
  allRows: ElementRef[];
  columnIndex: number = 3;
  thCounter: number = 0;
  initialIndex: number = 3;
  w: number;
  followingStatus: string;

  validatorsSubscription: Subscription;
  authSubcription: Subscription;

  isFetching: boolean = true;
  error = null;
  dtTrigger: Subject<any> = new Subject<any>();
  tabletQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  pagination: any = false;
  dyorPaginatorLenght: number;
  dyorPaginatorSize: number = 12;
  sortOption: string = 'uptime';
  sortGeneral: string = '';
  isAuthenticated: boolean = false;


  // @ViewChild('form', {static: true}) form: NgForm;

  
  constructor(private _store: Store<AppStore>,
              private _renderer: Renderer2,
              private validatorService: ValidatorService,
              public dialog: MatDialog,
              cd: ChangeDetectorRef,
              media: MediaMatcher) {

                this.tabletQuery = media.matchMedia(environment.tabletSize);
                this._mobileQueryListener = () => cd.detectChanges();
                this.tabletQuery.addListener(this._mobileQueryListener);

                this.authSubcription = this._store.select('auth').subscribe(
                  auth => {
                    if (!!auth.user) {
                      this.isAuthenticated = true
                    }
                  }
                )

              }


  ngOnInit(): void {

    this.w = window.innerWidth;

    this.validatorsSubscription = this._store.select('validatorsList')
    .pipe(
      skipWhile(
        val => val.validators.length == 0
      ),
      take(1),
      map(
        res => {
          let eligibleValidators = [];

          res.validators.forEach( validator => {
            if (validator['active-status'] === 'active') {
              eligibleValidators.push(validator);
            }
          })

          return eligibleValidators;
        }
      ),
    )
    .subscribe(
      responseData => {
        // console.log(responseData)
        const validatorsList: TableData[] = [];

        let networkTotalStake: number = 0;

        for (const key in responseData) {

          const lifetimeEpochInfo = responseData[key].lifetime['epoch-apr'] != null ? responseData[key].lifetime['epoch-apr'] : [];
          const lifetimeBlocksReport = responseData[key].lifetime['epoch-blocks'];
          const currentEpochPerformance = responseData[key]['current-epoch-performance'];

          const tempData: TableData = {
            id: responseData[key].id,
            status: responseData[key]['epos-status'] === 'currently elected' ? 'Elected' : 'Not elected',
            name: responseData[key].validator.name,
            website: responseData[key].validator.website,
            details: responseData[key].validator.details,
            creationHeight: responseData[key].validator['creation-height'],
            lastEpochCommittee: responseData[key].validator['last-epoch-in-committee'],
            address: responseData[key].validator.address,
            totalStake: responseData[key]['total-delegation'] / 1e18,
            stakeWeight: '0',
            fee: (+responseData[key].validator.rate * 100).toString(),
            maxFee: (+responseData[key].validator['max-rate'] * 100).toString(),
            maxFeeChange: (+responseData[key].validator['max-change-rate'] * 100).toString(),
            latestExpectedReturn: (lifetimeEpochInfo.length != 0) ? (+lifetimeEpochInfo[lifetimeEpochInfo.length - 1].apr * 100).toString() : '0',
            lifetimeRewards: responseData[key].lifetime['reward-accumulated'] / 1e18,
            uptimeAllAVG: (responseData[key].lifetime['blocks']['signed'] * 100) / responseData[key].lifetime['blocks']['to-sign'],
            uptime30AVG: (lifetimeBlocksReport != null) ? this.calculate30SignesAVG(lifetimeBlocksReport) : 0,
            currentEpochSigningPercent: (currentEpochPerformance != null) ? (+currentEpochPerformance['current-epoch-signing-percent']['current-epoch-signing-percentage'] * 100).toString() : null,
          }

          validatorsList.push(tempData);
          networkTotalStake += responseData[key]['total-delegation'];  
        }

        for (const key in validatorsList) {
          validatorsList[key].stakeWeight = this.validatorService.generateStakeWeight(validatorsList[key].totalStake, networkTotalStake / 1000000000000000000);
        }

        this.dyorPaginatorLenght = validatorsList.length
        this.validators = validatorsList
        this.validatorService.validatorsData = validatorsList

        this.currentStakedList = validatorsList
          .sort( (a, b) => a['totalStake'] > b['totalStake'] ? -1 : 1 )
          .slice(0, this.dyorPaginatorSize)

        this.currentFeeList = validatorsList
          .sort( (a, b) => +a['fee'] < +b['fee'] ? -1 : 1 )
          .slice(0, this.dyorPaginatorSize)

        this.currentERList = validatorsList
          .sort( (a, b) => +a['latestExpectedReturn'] > +b['latestExpectedReturn'] ? -1 : 1 )
          .slice(0, this.dyorPaginatorSize)

      },
      error => {
        this.isFetching = false;
        this.error = error.message;
      }
    )
    .add(
      () => {
        this.dtTrigger.next();
        this.isFetching = false;
      }
    )

  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.w = event.target.innerWidth;
    this.columnIndex = this.initialIndex; // rest first visible column by each resize
    this.configTable(this.initialIndex, this.w);
  }


  // onCheckBookmark(e: boolean) {
  //   this.followingStatus = e ? 'unfollow' : 'follow'
  // }


  // filterStatus(e) {
  //   console.log(e)
  // }

  ngAfterViewInit(): void {
    this.rowsContainer.changes.subscribe(
      t => {
        this.configTable(this.initialIndex, this.w);
      }
    )
  }

  configTable(calledIndex: number, innerWidth: number) {
    this.thCounter = 0;
      // reset thCounter, because this function runs by each click
      // so will change default thCounter during running app,
      // so we make sure its stable

    if (innerWidth <= 767 && innerWidth > 568) {
  
      this.rowsContainer.forEach(
        row => {
          const rowsArray = [...row.nativeElement.children];
  
          rowsArray.forEach( (col, index) => {
              // counts number of columns inside th rows (thead + tfoot)
              if (col.classList.contains('table-list__th')) {
                this.thCounter++
              }
  
              // disappear all exept calledIndex (it contains hiding previous shown index)
              if (
                col.classList.contains('table-list__td--dynamic') ||
                col.classList.contains('table-list__th--dynamic')
              ) {
                this._renderer.setStyle(col, 'display', 'none')
              }
  
              // make sure calledIndex is shown
              if ( index == calledIndex || index == calledIndex + 1 ) {
                this._renderer.setStyle(col, 'display', 'table-cell')
              }
            }
          );
  
        }
      )
  
    } else if (innerWidth <= 568) {
  
      this.rowsContainer.forEach(
        row => {
          const rowsArray = [...row.nativeElement.children];
  
          rowsArray.forEach( (col, index) => {
              // counts number of columns inside th rows (thead + tfoot)
              if (col.classList.contains('table-list__th')) {
                this.thCounter++
              }
  
              // disappear all exept calledIndex (it contains hiding previous shown index)
              if (
                col.classList.contains('table-list__td--dynamic') ||
                col.classList.contains('table-list__th--dynamic')
              ) {
                this._renderer.setStyle(col, 'display', 'none')
              }
  
              // make sure calledIndex is shown
              if ( index == calledIndex ) {
                this._renderer.setStyle(col, 'display', 'table-cell')
              }
            }
          );
  
        }
      )

    } else {
      this.rowsContainer.forEach(
        row => {
          const rowsArray = [...row.nativeElement.children];
  
          rowsArray.forEach( col => {
              // disappear all exept calledIndex (it contains hiding previous shown index)
              if (
                col.classList.contains('table-list__td--dynamic') ||
                col.classList.contains('table-list__th--dynamic')
              ) {
                this._renderer.setStyle(col, 'display', 'table-cell')
              }

            }
          );
  
        }
      )

    }

  }


  onTableScroll(index: number) {
    if (this.w <= 767 && this.w > 568) {
      this.getColumn(this.columnIndex += index * 2, false)
    } else {
      this.getColumn(this.columnIndex += index, true)
    }
  }


  openValidatorModal(validator: TableData) {
    this.dialog.open(ValidatorModalComponent, {
      width: '500px',
      data: validator
    })
  }


  getColumn(calledIndex: number, mobile: boolean) {

    // const counter = mobile ? this.thCounter / 2 : this.thCounter / 2 - this.initialIndex
    this.columnIndex = calledIndex;

    if (mobile) {

      const counter = this.thCounter / 2

      if (calledIndex > counter - 1) {
        this.columnIndex = this.initialIndex;
      }
  
      if (calledIndex < this.initialIndex) {
        this.columnIndex = counter - 1
      }

    } else {

      const counter = this.thCounter / 2 - this.initialIndex;

      if (calledIndex > counter + 1) {
        this.columnIndex = this.initialIndex;
      }
  
      if (calledIndex < this.initialIndex) {
        this.columnIndex = counter - 1
      }
  
    }

    // the function to disapear all and appear only one
    this.configTable(this.columnIndex, this.w)
  }


  calculate30SignesAVG(value) {

    let sum = 0;
    for( let i = 0; i < value.length; i++) {
        // console.log(value[i].blocks)
      if (value[i].blocks['signed'] == 0 || value[i].blocks['to-sign'] == 0){
        sum += 0
      } else {
        sum += (value[i].blocks['signed'] * 100) / value[i].blocks['to-sign']
      }
    }
    // console.log('uptime: ' + sum / value.length)
    return sum / value.length;
  }


  generateStakeWeight(validatorTotalStake: number, networkTotalStake: number): string {
    return ((validatorTotalStake / networkTotalStake) * 100).toString();
  }

  ngOnDestroy() {
    if (this.validatorsSubscription) {
      this.validatorsSubscription.unsubscribe();
    }

    if (this.authSubcription) {
      this.authSubcription.unsubscribe();
    }

    this.tabletQuery.removeListener(this._mobileQueryListener);

  }

}
