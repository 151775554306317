import { Actions, ofType, Effect } from '@ngrx/effects';

import { AuthActionTypes } from './auth.actions';
import * as AuthActions from './auth.actions';
import * as FollowingActions from '../following/following.actions';

import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { AuthResponseData, User } from '../../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/users/user.service';



const handleAuthenctication = (
    email: string,
    userId: string,
    token: string,
    expiresIn: number,
    callSignup: boolean
) => {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000)


    const user = new User(
        email,
        userId,
        token,
        expirationDate
    );
  
    localStorage.setItem('userData', JSON.stringify(user));


    if (callSignup) {

        return new AuthActions.SignupSuccess(
            {
                email: email,
                userId: userId,
                token: token,
                expirationDate: expirationDate
            }
        );
    
    } else {

        return new AuthActions.AuthenticateSuccess(
            {
                email: email,
                userId: userId,
                token: token,
                expirationDate: expirationDate
            }
        );
    
    }
    
}



const handleError = (errorRes: any) => {
    let errorMessage = 'An unknown error occurred!';

    if (!errorRes.error || !errorRes.error.error) {
        return of(new AuthActions.AuthenticateFail(errorMessage));
    }

    switch (errorRes.error.error.message) {
        case 'EMAIL_EXISTS':
            errorMessage = 'This email exists already';
            break;
        case 'EMAIL_NOT_FOUND':
            errorMessage = 'There is no user record corresponding to this identifier';
            break;
        case 'INVALID_PASSWORD':
            errorMessage = 'The password is invalid';
            break;
        }
    return of(new AuthActions.AuthenticateFail(errorMessage));
}



@Injectable()
export class AuthEffects {
    @Effect()
    authSignup = this.actions$.pipe(
        ofType(AuthActionTypes.SIGNUP_START),
        switchMap(
            (signupData: AuthActions.SignupStart) => {
                return this.http.post<AuthResponseData>(
                    'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + environment.firebaseAPIKey,
                    {
                      email: signupData.payload.email,
                      password: signupData.payload.password,
                      returnSecureToken: true
                    }
                ).pipe(
                    tap(
                        resData => {
                            this.authService.setLogoutTimer(+resData.expiresIn * 1000);
                        }
                    ),
                    map(
                        resData => {
                            return handleAuthenctication(
                                resData.email,
                                resData.localId,
                                resData.idToken,
                                +resData.expiresIn,
                                true
                            );
                        }
                    ),
                    catchError(
                        errorRes => {
                            return handleError(errorRes);
                        }
                    )
                );
            }
        )
    );


    @Effect()
    authLogin = this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_START),
        switchMap(
            (authData: AuthActions.LoginStart) => {
                return this.http.post<AuthResponseData>(
                    'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + environment.firebaseAPIKey,
                    {
                      email: authData.payload.email,
                      password: authData.payload.password,
                      returnSecureToken: true
                    }
                ).pipe(
                    tap(
                        resData => {
                            this.authService.setLogoutTimer(+resData.expiresIn * 1000);
                        }
                    ),
                    map(
                        resData => {
                            return handleAuthenctication(
                                resData.email,
                                resData.localId,
                                resData.idToken,
                                +resData.expiresIn,
                                false
                            );

                        }
                    ),
                    catchError(
                        errorRes => {
                            return handleError(errorRes);
                        }
                    )
                );
            }
        )
    );


    @Effect()
    autoLogin = this.actions$.pipe(
        ofType(AuthActionTypes.AUTO_LOGIN),
        // here we use map() with empty argumaent 
        // because we dont need to backend, we want get data from localstorage
        // and if it contained data, will call state action success
        map(
            () => {
                const userData: {
                    email: string;
                    id: string;
                    _token: string;
                    _tokenExpirationDate: string
                } = JSON.parse(localStorage.getItem('userData'));
            
                if(!userData) {
                    return { type: '--anonymouse--' };
                }
            
                const loadedUser = new User(
                    userData.email,
                    userData.id,
                    userData._token,
                    new Date(userData._tokenExpirationDate)
                );
            
                if (loadedUser.token) {

                    const expirationDuration =
                        new Date(userData._tokenExpirationDate).getTime() -
                        new Date().getTime()

                    this.authService.setLogoutTimer(expirationDuration);

                    return new AuthActions.AuthenticateSuccess({
                        email: loadedUser.email,
                        userId: loadedUser.id,
                        token: loadedUser.token,
                        expirationDate: new Date(userData._tokenExpirationDate)
                    })

                }
                return { type: '--anonymouse--' };
            }
        )
    );



    @Effect({dispatch: false})
    authRedirect = this.actions$.pipe(
        ofType(AuthActionTypes.AUTHENTICATE_SUCCESS),
        map(
            (authData: AuthActions.AuthenticateSuccess) => {
                this.router.navigate(['user', authData.payload.userId]);
            }
        ),
    );


    @Effect({dispatch: false})
    signupRedirect = this.actions$.pipe(
        ofType(AuthActionTypes.SIGNUP_SUCCESS),
        tap(
            (authData: AuthActions.SignupSuccess) => {
                this.userService.setUserProfile(authData.payload.userId);
            }
        ),
        map(
            (authData: AuthActions.SignupSuccess) => {
                // this.userService.setUserProfile(authData.payload.userId);
                this.router.navigate(['user', authData.payload.userId]);
            }
        ),
    );



    @Effect({dispatch: false})
    authLogout = this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(
            () => {
                this.authService.clearLogoutTimer();
                localStorage.removeItem('userData');
                this.router.navigate(['/authentication']);
                this.userService.flushUserFollowing();
            }
        )
    );


    constructor(private actions$: Actions,
                private http: HttpClient,
                private authService: AuthService,
                private userService: UserService,
                private router: Router) {}


}