import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tothemoon-snackbar',
  // templateUrl: './tothemoon-snackbar.component.html',
  template: '<a [href]="oneHashtag" target="_blank" class="snackbar-tothemoon"><span class="snackbar-tothemoon__text"><i>TO THE MOON!</i></span><span class="snackbar-tothemoon__rocket" [innerHTML]="rocketEmoji | sanitizer: \'html\'"></span></a>',
  styleUrls: ['./tothemoon-snackbar.component.scss']
  // styles: ['.snackbar-tothemoon { display: flex; justify-content: space-between; text-decoration: none; color: #fff;}']
})
export class TothemoonSnackbarComponent implements OnInit {

  oneHashtag: string = 'https://twitter.com/search?q=%24one&src=typed_query&f=live';
  rocketEmoji: string = "&#128640;";

  constructor() { }

  ngOnInit(): void { }

}
