import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';
import * as AuthActions from '../../shared/states/auth/auth.actions';
import { AuthAlertComponent } from 'src/app/shared/alerts/auth-alert/auth-alert.component';
import { PlaceholderDirective } from 'src/app/shared/placeholders/placeholder.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  isLoginMode: boolean = true;
  error: string = null;
  isPopup: boolean = false;
  isAuthenticated: boolean = false;
  private userSubcription: Subscription;
  private authSubcription: Subscription;
  private closeSubcription: Subscription;


  @ViewChild(PlaceholderDirective, {static: false}) alertHost: PlaceholderDirective;


  constructor(private compoentFactoryResolver: ComponentFactoryResolver,
              public dialogRef: MatDialogRef<AuthComponent>,
              @Inject(MAT_DIALOG_DATA) public data: boolean,
              private _store: Store<AppStore>) { }

  
  ngOnInit(): void {

    this.isPopup = this.data ? this.data : false

    // this.userSubcription = this.store.select('auth')
    // .pipe(
    //   map(
    //     authState => {
    //       return authState.user
    //     }
    //   )
    // )
    // .subscribe( user => {
    //   this.isAuthenticated = !!user;
    // });

    this.authSubcription = this._store.select('auth')
      .subscribe( authState => {

        this.isLoading = authState.loading;
        this.error = authState.authError;
        this.isAuthenticated = !!authState.user;

        if (this.error) {
          this.showErrorAlert(this.error);
        }

      }
    );
  }

  private showErrorAlert(message: string){
    const alertCmpFactory = this.compoentFactoryResolver.resolveComponentFactory(
      AuthAlertComponent
    );

    const hostViewContainerRef = this.alertHost.viewContainerRef;
    hostViewContainerRef.clear();

    const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);

    componentRef.instance.message = message;

    this.closeSubcription = componentRef.instance.close.subscribe(
      () => {
        this.closeSubcription.unsubscribe();
        hostViewContainerRef.clear();
      }
    )
  }


  onHandleError() {
    this._store.dispatch(new AuthActions.ClearError());
  }


  onSwitchMode() {
    this.isLoginMode = !this.isLoginMode;
  }


  onLogout() {
    this._store.dispatch(new AuthActions.Logout());
  }


  onSubmit(form: NgForm) {
    if (!form.valid) { return; }

    const email = form.value.email;
    const password = form.value.password;

    if (this.isLoginMode) {
      this._store.dispatch(new AuthActions.LoginStart({
        email: email,
        password: password
      }));
    } else {
      this._store.dispatch(new AuthActions.SignupStart({
        email: email,
        password: password
      }));
    }

    // console.log(form.value);
    form.reset();
  }
  

  onNoClick(): void{
    this.dialogRef.close();
  }


  ngOnDestroy() {

    if (this.userSubcription) {
      this.userSubcription.unsubscribe();
    }

    if (this.authSubcription) {
      this.authSubcription.unsubscribe();
    }
    
    if (this.closeSubcription) {
      this.closeSubcription.unsubscribe();
    }

  }

}
