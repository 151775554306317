import { Component, OnInit, OnDestroy } from '@angular/core';
import * as AuthActions from './shared/states/auth/auth.actions';
import { Store } from '@ngrx/store';
import { AppStore } from './shared/states/app.reducer';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap, switchMap, mergeMap } from 'rxjs/operators';
import { Subscription, Observable, timer, forkJoin } from 'rxjs';
import * as FollowingActions from './shared/states/following/following.actions';
import * as ValidatorActions from './shared/states/validator/validator.actions';
import * as fromAuth from './shared/states/auth/auth.reducer';
import { NetworkService } from './shared/services/network/network.service';



@Component({
  selector: 'app-root',
  // templateUrl: './app.component.html',
  template: '<app-side-bar></app-side-bar>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'symphonie-one-client';

  authSubcription: Subscription;
  intervalSubscription: Subscription;
  followingSubscription: Subscription;
  
  
  constructor(private store: Store<AppStore>,
              private networkService: NetworkService) {

    this.store.dispatch(new AuthActions.AutoLogin());

  }



  ngOnInit() {

    this.store.dispatch(new ValidatorActions.LoadValidatorsStart());

    this.authSubcription = this.store.select('auth').subscribe(
        auth => {
          // console.log(state)
          if (!!auth.user) {
            // console.log(auth.user.id)
            
            this.store.dispatch(new FollowingActions.LoadFollowingStart(auth.user.id));
            // this.followingSubscription = this.store.select('followingsList').subscribe(
            //   state => {
            //     if (!state.isUpdated) {
            //       this.store.dispatch(new FollowingActions.LoadFollowingStart(auth.user.id));
            //     }
            //   }
            // )
          }
          
        }
    )

    this.updateNetworkState();
    
  }


  updateNetworkState() {
    this.intervalSubscription = timer(0, 30000)
      .pipe(
        mergeMap(
          () => {
            const network = this.networkService.getStakingNetworkInfo()
            const epoch = this.networkService.getNodeMetadata()

            return forkJoin([network, epoch]);
          }
        )
      )
      .subscribe(

        result => {
          this.networkService.setTotalStakingSubject(
            result[0]['epoch-last-block'],
            result[0]['total-staking'] / 1e18
          )

          this.networkService.setCurrentBlockSubject(
            result[1]['blocks-per-epoch'],
            result[1]['current-block-number'],
            result[1]['current-epoch']
          )
        }

      )
  }


  ngOnDestroy() {
    if (this.authSubcription) {
      this.authSubcription.unsubscribe();
    }


    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    if (this.followingSubscription) {
      this.followingSubscription.unsubscribe();
    }

  }

}
