import { Actions, ofType, Effect } from '@ngrx/effects';

import { FollowingActionTypes } from './following.actions';
import * as FollowingActions from './following.actions';

import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { Profile } from '../../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';



const handleError = (errorRes: any) => {
    let errorMessage = 'An unknown error occurred!';

    if (!errorRes.error || !errorRes.error.error) {
        return of(new FollowingActions.LoadFollowingFail(errorMessage));
    }

    switch (errorRes.error.error.message) {
        case 'EMAIL_EXISTS':
            errorMessage = 'This email exists already';
            break;
        case 'EMAIL_NOT_FOUND':
            errorMessage = 'There is no user record corresponding to this identifier';
            break;
        case 'INVALID_PASSWORD':
            errorMessage = 'The password is invalid';
            break;
        }
    return of(new FollowingActions.LoadFollowingFail(errorMessage));
}



@Injectable()
export class FollowingEffects {


    @Effect()
    followingLoad = this.actions$.pipe(
        ofType(FollowingActionTypes.LOAD_FOLLOWING_START),
        // tap(
        //     res => console.log(res)
        // ),
        switchMap(
            (userData: FollowingActions.LoadFollowingStart) => {
                // console.log(userData)
                return this.http.get<{ [key: string]: Profile }>(
                    environment.firebaseDB + 'users.json'
                ).pipe(
                    map(
                        users => {
                            // console.log(users)
                            for (const key in users) {
                                if (users.hasOwnProperty(key)) {
                                    if (users[key].id === userData.payload) {
                                        // console.log(users[key])
                                        return new FollowingActions.LoadFollowingSuccess( users[key].following );
                                    }
                                }
                            }
                        }
                    ),
                    catchError( errorRespnse => {
                        // Send to analytics server
                        // console.log(errorRespnse)
                        return handleError(errorRespnse);
                    })
                );

            }
        )
    );


    @Effect({dispatch: false})
    followingSuccess = this.actions$.pipe(
        ofType(FollowingActionTypes.LOAD_FOLLOWING_SUCCESS),
        tap(
            (authData: FollowingActions.LoadFollowingSuccess) => {
                // console.log(authData.payload)

                // this.router.navigate(['user', authData.payload]);
                // this.router.navigate(['/']);
                // console.log(authData.payload.userId)
            }
        )
    );

    

    @Effect({dispatch: false})
    flushFollowing = this.actions$.pipe(
        ofType(FollowingActionTypes.FLUSH_FOLLOWING),
        tap(
            () => {
                console.log('following already cleaned');
                this._auth.clearFollowingList();
            }
        )
    );



    constructor(private actions$: Actions,
                private http: HttpClient,
                private _auth: AuthService,
                private router: Router) {}


}