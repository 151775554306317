import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './feature/home-page/home-page.component';
import { AuthComponent } from './feature/auth/auth.component';
import { MessagePageComponent } from './feature/message-page/message-page.component';
import { NotFoundPageComponent } from './feature/not-found-page/not-found-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'authentication', component: AuthComponent },
  { path: 'validator', redirectTo: '/validator', pathMatch: 'full' },
  { path: 'user', redirectTo: '/user', pathMatch: 'full' },
  { path: 'signup/success', component: MessagePageComponent },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', redirectTo: '/404' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
