<!-- <ul>
  <li *ngFor="let v of (validators | async).validators">{{ v.name }}</li>
</ul> -->


<div class="user-dashboard container-fluid pb-5">
  <div class="user-dashboard__headline">
      <p class="following-counter">Following</p>
  </div>


</div>

