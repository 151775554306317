<div class="container-fluid px-0 mx-0">

    <div class="loading-top-navbar"
        [ngClass]="mobileQuery.matches ? 'nav-is-mobile' : 'nav-is-desktop'">

        <app-dummy-box class="d-flex justify-content-center align-items-center mx-1 mx-md-4"
                       [dummyCircle]="true"
                       [dummyWidth]="25"
                       [dummyHeight]="25">
        </app-dummy-box>


        <div class="loading-top-navbar__navigation w-50" *ngIf="!mobileQuery.matches">
            <app-dummy-box class="d-flex justify-content-center align-items-center"
                           [dummyWidth]="60"
                           [cornerRadius]="5"
                           [dummyHeight]="25">
            </app-dummy-box>
            <app-dummy-box class="d-flex justify-content-center align-items-center"
                           [dummyWidth]="60"
                           [cornerRadius]="5"
                           [dummyHeight]="25">
            </app-dummy-box>
            <app-dummy-box class="d-flex justify-content-center align-items-center"
                           [dummyWidth]="60"
                           [cornerRadius]="5"
                           [dummyHeight]="25">
            </app-dummy-box>
        </div>


        <app-dummy-box class="d-flex justify-content-center align-items-center"
                       *ngIf="mobileQuery.matches"
                       [dummyCircle]="true"
                       [dummyWidth]="25"
                       [dummyHeight]="25">
        </app-dummy-box>
        <app-dummy-box class="d-flex justify-content-center align-items-center"
                       *ngIf="mobileQuery.matches"
                       [dummyCircle]="true"
                       [dummyWidth]="25"
                       [dummyHeight]="25">
        </app-dummy-box>
        <app-dummy-box class="d-flex justify-content-center align-items-center"
                       *ngIf="mobileQuery.matches"
                       [dummyCircle]="true"
                       [dummyWidth]="25"
                       [dummyHeight]="25">
        </app-dummy-box>


        <app-dummy-box class="d-flex justify-content-center align-items-center mx-1 mx-md-4"
                       [dummyCircle]="true"
                       [dummyWidth]="25"
                       [dummyHeight]="25">
        </app-dummy-box>
    </div>




    <div class="container validators-home-loading">

        <app-dummy-box class="validators-home-loading__search-bar d-flex justify-content-center align-items-center"
                        *ngIf="!mobileQuery.matches"
                        [dummyWidth]="100"
                        [dummyPrecentWidth]="true"
                        [dummyHeight]="51">
        </app-dummy-box>



        <div class="validators-home-loading__elected-section">

            <app-dummy-box class="d-flex align-items-center title title-text"
                            [dummyWidth]="210"
                            [cornerRadius]="5"
                            [dummyHeight]="35">
            </app-dummy-box>

            <div class="list-wrapper">
                
                <div class="list-wrapper__validator-box">
                    <app-dummy-box class="square-box"
                                    [dummyWidth]="100"
                                    [dummyPrecentWidth]="true"
                                    [displayMode]="'inline-block'"
                                    [cornerRadius]="15"
                                    [dummyHeight]="350">
                    </app-dummy-box>
                    <app-dummy-box class="d-flex align-items-center title title-text"
                                    [dummyWidth]="150"
                                    [cornerRadius]="5"
                                    [dummyHeight]="30">
                    </app-dummy-box>
                </div>

                <div class="list-wrapper__validator-box">
                    <app-dummy-box class="square-box"
                                    [dummyWidth]="100"
                                    [dummyPrecentWidth]="true"
                                    [displayMode]="'inline-block'"
                                    [cornerRadius]="15"
                                    [dummyHeight]="350">
                    </app-dummy-box>
                    <app-dummy-box class="d-flex align-items-center title title-text"
                                    [dummyWidth]="150"
                                    [cornerRadius]="5"
                                    [dummyHeight]="30">
                    </app-dummy-box>
                </div>
            
                <div class="list-wrapper__validator-box">
                    <app-dummy-box class="square-box"
                                    [dummyWidth]="100"
                                    [dummyPrecentWidth]="true"
                                    [displayMode]="'inline-block'"
                                    [cornerRadius]="15"
                                    [dummyHeight]="350">
                    </app-dummy-box>
                    <app-dummy-box class="d-flex align-items-center title title-text"
                                    [dummyWidth]="150"
                                    [cornerRadius]="5"
                                    [dummyHeight]="30">
                    </app-dummy-box>
                </div>
                
                <div class="list-wrapper__validator-box">
                    <app-dummy-box class="square-box"
                                    [dummyWidth]="100"
                                    [dummyPrecentWidth]="true"
                                    [cornerRadius]="15"
                                    [displayMode]="'inline-block'"
                                    [dummyHeight]="350">
                    </app-dummy-box>
                    <app-dummy-box class="d-flex align-items-center title title-text"
                                    [dummyWidth]="150"
                                    [cornerRadius]="5"
                                    [dummyHeight]="30">
                    </app-dummy-box>
                </div>

            </div>

        </div>


        <app-dummy-box class="validators-home-loading__banner-section d-flex align-items-center"
                        [dummyWidth]="100"
                        [dummyPrecentWidth]="true"
                        [cornerRadius]="15"
                        [dummyHeight]="350">
        </app-dummy-box>

    </div>

</div>