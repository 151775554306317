import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TableData } from 'src/app/shared/models/validator.model';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  // styleUrls: ['./search-bar.component.scss'],
  styles: ['.search-bar__full-width{ width: 100%};']
})
export class SearchBarComponent implements OnInit {

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  autoCompleteList: any[];

  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;
  @Output() onSelectedOption = new EventEmitter();
  @Input() allValidators: TableData[];

  options: TableData[];

  validatorsSubscription: Subscription;


  constructor(private validatorService: ValidatorService) { }



  ngOnInit(): void {

    this.validatorService.validatorsData = this.allValidators

    this.options = this.validatorService.searchOption;

    // when user types something in input field, the value changes will come through this
    this.myControl.valueChanges.subscribe(
      userInput =>  {
        // console.log(userInput)
        this.autoCompleteExpenseList(userInput);
      }
    )

  }

  // private autoCompleteExpenseList(input) {
  private autoCompleteExpenseList(input: string) {
    let categoryList = this.filterCategoryList(input);
    this.autoCompleteList = categoryList;
    // console.log(categoryList)
  }


  // this is where filtering the data happens
  filterCategoryList(value) {

    var categoryList = [];
    if (typeof value != "string") {
      return [];
    }

    if (value === '' || value === null) {
      return [];
    }

    // return value ? this.allValidators.filter(
    //   s => s.name.toLowerCase().indexOf(value.toLowerCase()) != -1
    // ) : this.allValidators

    return value ? this.allValidators.filter(
      s => s.name.toLowerCase().indexOf(value.toLowerCase()) != -1 || s.address.toLowerCase().indexOf(value.toLowerCase()) != -1
    ) : this.allValidators

  }


  // after user clicked an auto suggest option, this function will show the field he wants
  displayFn(v: TableData) {
    let k = v ? v.name : v;
    return k;
  }


  filterValidatorList(event) {
    var validators = event.source.value;

    if (!validators) {
      this.validatorService.searchOption = [];
    } else {
      this.validatorService.searchOption.push(validators);
      this.onSelectedOption.emit(this.validatorService.searchOption);
    }

    this.focusOnPlaceInput();
  }


  focusOnPlaceInput() {
    this.autoCompleteInput.nativeElement.focus();
    this.autoCompleteInput.nativeElement.value = '';
  }


  removeOption(option) {
    // console.log(option)

    let index = this.validatorService.searchOption.indexOf(option);

    if (index >= 0) {
      this.validatorService.searchOption.splice(index, 1);
    }


    this.focusOnPlaceInput();
    this.onSelectedOption.emit(this.validatorService.searchOption);
  }


}
