import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';

@Component({
  selector: 'app-user',
  // templateUrl: './user.component.html',
  template: '<div class="container-fluid"><router-outlet></router-outlet></div>',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  loggedinUser: string;
  routeSubscription: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store<AppStore>) { }

  ngOnInit(): void {

    // this.loggedinUser = localStorage.getItem('id');

    // or
    // ask auth state to get user id

    this.routeSubscription = this.route.params.subscribe(
      (params: Params) => {
        this.loggedinUser = params['id'];
        // this.store.select('followingsList')
      }
    );

  }


  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}
