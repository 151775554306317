import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppStore } from 'src/app/shared/states/app.reducer';
import { Store } from '@ngrx/store';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { TableData } from 'src/app/shared/models/validator.model';
import { Subscription, Subject } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';

@Component({
  selector: 'app-following-list',
  templateUrl: './following-list.component.html',
  styleUrls: ['./following-list.component.scss']
})
export class FollowingListComponent implements OnInit, OnDestroy {



  validators: TableData[];
  searchOutput: TableData[];
  currentStakedList: TableData[];
  currentERList: TableData[];
  currentFeeList: TableData[];
  isFetching: boolean = true;
  isSearching: boolean = false;
  error = null;
  dtTrigger: Subject<any> = new Subject<any>();

  followingList: string[] = [];

  validatorsSubscription: Subscription;
  followingSubscription: Subscription;
  tabletQuery: MediaQueryList;


  constructor(private store: Store<AppStore>,
              public dialog: MatDialog,
              private validatorService: ValidatorService,
              cd: ChangeDetectorRef,
              media: MediaMatcher) {

                this.tabletQuery = media.matchMedia(environment.tabletSize);
                this._mobileQueryListener = () => cd.detectChanges();
                this.tabletQuery.addListener(this._mobileQueryListener);

              }


  ngOnInit(): void {

    // {followings: Array(2), isUpdated: true}
    console.log('load here')

    this.followingSubscription = this.store.select('followingsList')
    .pipe(
      skipWhile(
        val => val.isUpdated == false
      ),
      map(
        response => {
          return response.followings.filter(f => f)
        }
      )
    )
    .subscribe(
      result => this.followingList = result.length > 0 ? result : []
    )



    this.validatorsSubscription = this.store.select('validatorsList')
    .pipe(
      skipWhile(
        val => val.validators.length == 0
      ),
      take(1),
      map(
        res => {
          let eligibleValidators = [];

          res.validators.forEach( validator => {
            if (validator['active-status'] === 'active') {
              eligibleValidators.push(validator);
            }
          })

          return eligibleValidators;
        }
      ),
    )
    .subscribe(
      responseData => {
        // console.log(responseData)
        const validatorsList: TableData[] = [];

        let networkTotalStake: number = 0;

        for (const key in responseData) {

          const lifetimeEpochInfo = responseData[key].lifetime['epoch-apr'] != null ? responseData[key].lifetime['epoch-apr'] : [];
          const lifetimeBlocksReport = responseData[key].lifetime['epoch-blocks'];
          const currentEpochPerformance = responseData[key]['current-epoch-performance'];

          const tempData: TableData = {
            id: responseData[key].id,
            status: responseData[key]['epos-status'] === 'currently elected' ? 'Elected' : 'Not elected',
            name: responseData[key].validator.name,
            website: responseData[key].validator.website,
            details: responseData[key].validator.details,
            creationHeight: responseData[key].validator['creation-height'],
            lastEpochCommittee: responseData[key].validator['last-epoch-in-committee'],
            address: responseData[key].validator.address,
            totalStake: responseData[key]['total-delegation'] / 1e18,
            stakeWeight: '0',
            fee: (+responseData[key].validator.rate * 100).toString(),
            maxFee: (+responseData[key].validator['max-rate'] * 100).toString(),
            maxFeeChange: (+responseData[key].validator['max-change-rate'] * 100).toString(),
            latestExpectedReturn: (lifetimeEpochInfo.length != 0) ? (+lifetimeEpochInfo[lifetimeEpochInfo.length - 1].apr * 100).toString() : '0',
            lifetimeRewards: responseData[key].lifetime['reward-accumulated'] / 1e18,
            uptimeAllAVG: (responseData[key].lifetime['blocks']['signed'] * 100) / responseData[key].lifetime['blocks']['to-sign'],
            uptime30AVG: (lifetimeBlocksReport != null) ? this.calculate30SignesAVG(lifetimeBlocksReport) : 0,
            currentEpochSigningPercent: (currentEpochPerformance != null) ? (+currentEpochPerformance['current-epoch-signing-percent']['current-epoch-signing-percentage'] * 100).toString() : null,
          }

          validatorsList.push(tempData);
          networkTotalStake += responseData[key]['total-delegation'];  
        }

        for (const key in validatorsList) {
          validatorsList[key].stakeWeight = this.validatorService.generateStakeWeight(validatorsList[key].totalStake, networkTotalStake / 1000000000000000000);
        }

        this.validators = validatorsList
        this.searchOutput = validatorsList
        this.validatorService.validatorsData = validatorsList

      },
      error => {
        this.isFetching = false;
        this.error = error.message;
      }
    )
    .add(
      () => {
        this.dtTrigger.next();
        this.isFetching = false;
      }
    )

  }

  calculate30SignesAVG(value) {

    let sum = 0;
    for( let i = 0; i < value.length; i++) {
        // console.log(value[i].blocks)
      if (value[i].blocks['signed'] == 0 || value[i].blocks['to-sign'] == 0){
        sum += 0
      } else {
        sum += (value[i].blocks['signed'] * 100) / value[i].blocks['to-sign']
      }
    }
    // console.log('uptime: ' + sum / value.length)
    return sum / value.length;
  }

  private _mobileQueryListener: () => void;


  getFilteredExpenseList() {
    if (this.validatorService.searchOption.length > 0) {
      // this.validators = this.validatorService.filteredListOptions();
      this.searchOutput = this.validatorService.filteredListOptions();
    } else {
      // this.validators = this.validatorService.validatorsData;
      this.searchOutput = this.validatorService.validatorsData;
    }
  }

  ngOnDestroy() {
    if (this.validatorsSubscription) {
      this.validatorsSubscription.unsubscribe();
    }

  
    if (this.followingSubscription) {
      this.followingSubscription.unsubscribe();
    }

  }
  
}
