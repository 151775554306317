import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-realtime-price',
  // templateUrl: './realtime-price.component.html',
  template: '<h1 class="price-ticker__price">${{ price }}</h1><i class="price-ticker__lable">ONEUSDT</i>',
  styleUrls: ['./realtime-price.component.scss']
})
export class RealtimePriceComponent implements OnInit {

  @Input() price: number;
  
  constructor() { }

  ngOnInit(): void { }

}
