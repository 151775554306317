<mat-toolbar class="top-navbar"
            [ngClass]="mobileIconsGroup ? 'nav-is-mobile' : 'nav-is-desktop'">

    <button mat-icon-button (click)="toggleSidebar()"
            class="navbar-icon mx-md-4">
        <!-- <mat-icon *ngIf="existsSellectionList; else simpleNotification"
                  matBadgeOverlap="true"
                  class="navbar-icon notification-icon"
                  matBadgeSize="small"
                  matBadgeColor="warn"
                  matBadge="&#8226;"
                  matBadgePosition="before">
        
            notes
        </mat-icon>

        <ng-template #simpleNotification>
            <mat-icon>notes</mat-icon>    
        </ng-template> -->
        <mat-icon class="navbar-icon notification-icon">
            notes
        </mat-icon>

    </button>

    <div class="top-navbar__navigation w-50" [class.d-none]="mobileIconsGroup">
        <a class="link-item" routerLinkActive="active" [routerLink]="['/']" [routerLinkActiveOptions]="{exact: true}">
            Home
        </a>
        <a class="link-item" routerLinkActive="active" [routerLink]="['/validator']" [routerLinkActiveOptions]="{exact: true}">
            Validators
        </a>

        <a class="link-item" routerLinkActive="active"
            aria-disabled="true"
            disabled
            *ngIf="!isAuthenticated"
            href="javascript:void(0)">
            <!-- [routerLink]="['/404']"> -->
            <!-- [routerLink]="['/authentication']"> -->
            Following
        </a>

        <a class="link-item"
           routerLinkActive="active"
           *ngIf="isAuthenticated"
           aria-disabled="true"
           href="javascript:void(0)">
           <!-- [routerLink]="['user', currentUser, 'following']"> -->

            Following
        </a>

    </div>


    <button mat-icon-button
            routerLinkActive="active" [routerLink]="['/followings']"
            [class.d-none]="!mobileIconsGroup"
            aria-disabled="true"
            disabled
            class="navbar-icon mx-md-4">
        <mat-icon>
            bookmark_border
        </mat-icon>
    </button>

    <button mat-icon-button
            routerLinkActive="active" [routerLink]="['/validator']"
            [class.d-none]="!mobileIconsGroup"
            class="navbar-icon mx-md-4">
        <mat-icon>
            <!-- calendar_view_month -->
            grid_view
        </mat-icon>
    </button>

    <button mat-icon-button
            [class.d-none]="!mobileIconsGroup"
            (click)="openSearchModal()"
            class="navbar-icon mx-md-4">
        <mat-icon>
            search
        </mat-icon>
    </button>

    <!-- <button mat-icon-button
            routerLinkActive="active" [routerLink]="['/profile']"
            class="navbar-icon mx-md-4">
        <mat-icon>
            person_outline
        </mat-icon>
    </button> -->

    <div class="top-navbar__profile">
        <button mat-icon-button class="navbar-icon mx-md-4" [matMenuTriggerFor]="beforeMenu">
            <mat-icon>
                person_outline
            </mat-icon>
    
        </button>
        <mat-menu class="profile-menu" #beforeMenu="matMenu" xPosition="before">

            <!-- <button mat-menu-item [routerLink]="['/authentication']" *ngIf="!isAuthenticated"> -->
            <button mat-menu-item
                    [routerLink]="['/']"
                    aria-disabled="true"
                    disabled        
                    *ngIf="!isAuthenticated">
                    <mat-icon>login</mat-icon>
              <span>Login</span>
            </button>

            <button [routerLink]="['user', currentUser]" 
                    mat-menu-item
                    aria-disabled="true"
                    disabled        
                    *ngIf="isAuthenticated">
                <mat-icon>settings</mat-icon>
                <span>Dashboard</span>
            </button>
  
            <!-- <button mat-menu-item [routerLink]="['/authentication']" *ngIf="!isAuthenticated"> -->
            <button mat-menu-item
                    [routerLink]="['/']"
                    aria-disabled="true"
                    disabled        
                     *ngIf="!isAuthenticated">
                    <mat-icon>app_registration</mat-icon>
              <span>Signup</span>
            </button>

            <button mat-menu-item
                    (click)="onLogout()"
                    aria-disabled="true"
                    disabled        
                    *ngIf="isAuthenticated">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
              </button>


            <mat-divider></mat-divider>

            <button mat-menu-item
                    aria-disabled="true"
                    disabled>
              <mat-icon>help_center</mat-icon>
              <span>Help</span>
            </button>
        </mat-menu>
          
    </div>


    
</mat-toolbar>
