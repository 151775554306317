<div *ngIf="isPopup == false; else loadPopup">



    <ng-template appPlaceholder></ng-template>

    <div class="container" style="padding-top: 70px;">
        <div class="row d-flex flex-column justify-content-center text-center"
            *ngIf="isAuthenticated">

            You already are logged in
            <button
                class="btn btn-primary mt-5"
                (click)="onLogout()"
                type="submit">
                    Logout
            </button>
    
        </div>
    
    
    
        <div class="row" *ngIf="!isAuthenticated">
            <div class="col-xs-12 col-md-6 col-md-offset-3 mx-auto">
    
                <div class="alert alert-danger" *ngIf="error">
                    <p class="mb-0">{{ error }}</p>
                </div>
    
                <div *ngIf="isLoading" class="text-center">
                    <app-spinner></app-spinner>
                </div>
    
                <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">
                    <div class="form-group">
                        <label for="email">E-Mail</label>
                        <input
                            type="email"
                            id="email"
                            class="form-control"
                            ngModel
                            name="email"
                            required
                            email/>
                    </div>
    
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            class="form-control"
                            ngModel
                            name="password"
                            required
                            minlength="6"/>
                    </div>
    
                    <div>
                        <button
                            class="btn btn-primary"
                            type="submit"
                            [disabled]="!authForm.valid">
                            {{ isLoginMode ? 'Login' : 'Sign Up' }}
                        </button>
                        |
                        <button class="btn btn-primary" (click)="onSwitchMode()" type="button">
                            Switch to {{ isLoginMode ? 'Sign Up' : 'Login' }}
                        </button>
                    </div>
    
                </form>
    
            </div>
        </div>
    </div>

</div>



<ng-template #loadPopup>

    
    <h5 class="text-center w-100"><strong>Log in to continue</strong></h5>

    <div class="col-xs-12 col-md-6 col-md-offset-3 mx-auto">
    

        <button
            class="btn btn-primary"
            [routerLink]="['/authentication']"
            (click)="onNoClick()"
            type="button">

            Login
        </button>
        |
        <button class="btn btn-primary"
                (click)="onNoClick()"
                [routerLink]="['/authentication']"
                type="button">
                
            Sign Up
        </button>



        <!-- <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">
            <div class="form-group input-group">

                <div class="input-group-prepend">
                    <span class="input-group-text material-icons" id="basic-addon-email">
                        alternate_email
                    </span>
                </div>

                <input
                    type="email"
                    id="email"
                    class="form-control"
                    ngModel
                    name="email"
                    placeholder="email@example.com"
                    aria-label="Email"
                    aria-describedby="basic-addon-email"
                    required
                    email/>
            </div>
              

            <div class="form-group input-group">

                <div class="input-group-prepend">
                    <span class="input-group-text material-icons" id="basic-addon-password">
                        lock
                    </span>
                </div>

                <input
                    type="password"
                    id="password"
                    class="form-control"
                    ngModel
                    name="password"
                    placeholder="******"
                    aria-label="Password"
                    aria-describedby="basic-addon-password"
                    required
                    minlength="6"/>
            </div>

            <div>
                <button
                    class="btn btn-primary"
                    type="submit"
                    [disabled]="!authForm.valid">
                    {{ isLoginMode ? 'Login' : 'Sign Up' }}
                </button>
                |
                <button class="btn btn-primary" (click)="onSwitchMode()" type="button">
                    Switch to {{ isLoginMode ? 'Sign Up' : 'Login' }}
                </button>
            </div>

        </form> -->

    </div>

</ng-template>
