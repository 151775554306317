export class User {
    constructor(
        public email: string,
        public id: string,
        private _token: string,
        private _tokenExpirationDate: Date
    ) {}

    get token() {
        if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
            return null;
        }
        return this._token;
    }
}


// export class Profile {
//     constructor(public id: string, public following: string[], public avatar: string) {}
// }

export class Profile {
    constructor(public id: string, public following: string[], public avatar: string) {}
}


// export class Profile {
//     public key: UserInfo;
  
//     constructor(public key: string) {}
// }

export interface AuthResponseData {
    kind: string;
    idToken: string,
    email: string,
    refreshToken: string,
    expiresIn: string,
    localId: string,
    registered?: boolean
}
  