import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/shared/services/network/network.service';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';



@Component({
  selector: 'app-epoch',
  templateUrl: './epoch.component.html',
  styleUrls: ['./epoch.component.scss']
})
export class EpochComponent implements OnInit {

  epochSubscription: Subscription;
  blockSubscription: Subscription;


  leftBlocks: number = 32768;

  currentEpoch: number = 0;
  currentBlock: number = 0;
  blockReference: number = 32768;
  epochStatus: number = 0;
  timeLable: string = null;
  blockHolder: number = 0;

  constructor(private networkService: NetworkService) { }



  ngOnInit(): void {

      this.epochSubscription = this.networkService.getCurrentBlockSubject()
      .pipe(
        skipWhile(
          b => b.length == 0
        )
      )
      .subscribe(
        current => {
          this.currentEpoch = current[2];
          this.currentBlock = current[1];
          this.blockReference = current[0]

          this.blockSubscription = this.networkService.getTotalStakingSubject()
          .pipe(
            skipWhile(
              s => s.length == 0
            )
          )
          .subscribe(
            res => {
              this.generateLeftBlock(res[0], current[1], current[2])
            }
          )

        }
      )

  }


  // generateLeftBlock(end: number, current: number) {
  //   this.leftBlocks = end - current;
  //   this.epochStatus = 100 - (this.leftBlocks * 100) / this.blockReference
  // }

  generateLeftBlock = async (end: number, current: number, epoch: number) => {
    if (this.currentEpoch == 0 || epoch == this.currentEpoch) {
      if (this.blockHolder == 0 || current > this.blockHolder) {
        this.blockHolder = current
        this.leftBlocks = await (end - current);
        this.timeLable = await this.generateTimeLabel(this.leftBlocks)
        this.epochStatus = await 100 - (this.leftBlocks * 100) / this.blockReference
      }  
    } else {
      this.blockHolder = current
      this.leftBlocks = await (end - current);
      this.timeLable = await this.generateTimeLabel(this.leftBlocks)
      this.epochStatus = await 100 - (this.leftBlocks * 100) / this.blockReference
    }
  }


  generateTimeLabel = async (count: number) => {
    const totalSeconds = await (count * 2)
    const hours = await (totalSeconds / 3600);
    const minutes = await (totalSeconds % 3600 / 60)
    if (Math.floor(hours) == 0) {
      return '~' + Math.floor(minutes) + " min";
    } else if (Math.floor(hours) == 0 && Math.floor(minutes) == 0) {
        return "get ready to move to the epoch " + (this.currentEpoch + 1) + "...";
    } else {
      return '~' + Math.floor(hours) + " hr " + Math.floor(minutes) + " min";
    }
  }


  ngOnDestroy(): void{
    if (this.epochSubscription) {
      this.epochSubscription.unsubscribe();
    }

    if (this.blockSubscription) {
      this.blockSubscription.unsubscribe();
    }

  }


}
