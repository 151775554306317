import { Pipe, PipeTransform } from '@angular/core';
import { TableData } from '../models/validator.model';

@Pipe({
  name: 'tableSort'
})
export class TableSortPipe implements PipeTransform {

  transform(value: TableData[], keys: {options: string, filterOptions: string}): TableData[] {

    if (!Array.isArray(value)) {
      return
    }

    // console.log(keys)


    if (keys.options) {

      let electedValidators = [];
      value.forEach( validator => {
        if (validator.status === 'Elected') {
          electedValidators.push(validator);
        }
      })

      switch (keys.filterOptions) {
        case 'fee': return electedValidators.sort( (a, b) => +a.fee < +b.fee ? -1 : 1 );
        case 'maxfee': return electedValidators.sort( (a, b) => +a.maxFee < +b.maxFee ? -1 : 1 );
        case 'feechange': return electedValidators.sort( (a, b) => +a.maxFeeChange < +b.maxFeeChange ? -1 : 1 );
        case 'staked': return electedValidators.sort( (a, b) => a.totalStake > b.totalStake ? -1 : 1 );
        case 'return': return electedValidators.sort( (a, b) => +a.latestExpectedReturn > +b.latestExpectedReturn ? -1 : 1 );
        case 'uptime': return electedValidators.sort( (a, b) => +a.uptime30AVG > +b.uptime30AVG ? -1 : 1 );
        case 'currentSigning': return electedValidators.sort( (a, b) => +a.currentEpochSigningPercent > +b.currentEpochSigningPercent ? -1 : 1 );
        default: return electedValidators
      }

    } else {

      switch (keys.filterOptions) { 
        case 'fee': return value.slice().sort( (a, b) => +a.fee < +b.fee ? -1 : 1 );
        case 'maxfee': return value.slice().sort( (a, b) => +a.maxFee < +b.maxFee ? -1 : 1 );
        case 'feechange': return value.slice().sort( (a, b) => +a.maxFeeChange < +b.maxFeeChange ? -1 : 1 );
        case 'staked': return value.slice().sort( (a, b) => a.totalStake > b.totalStake ? -1 : 1 );
        case 'return': return value.slice().sort( (a, b) => +a.latestExpectedReturn > +b.latestExpectedReturn ? -1 : 1 );
        case 'uptime': return value.slice().sort( (a, b) => +a.uptime30AVG > +b.uptime30AVG ? -1 : 1 );  
        case 'currentSigning': return value.slice().sort( (a, b) => +a.currentEpochSigningPercent > +b.currentEpochSigningPercent ? -1 : 1 );  
        default: return value;
      }

    }

  }

}
