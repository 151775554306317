import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RealtimePriceComponent } from './realtime-price.component';


const COMPONENTS = [
  RealtimePriceComponent
]


@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    CommonModule
  ],
  exports: COMPONENTS
})
export class RealtimePriceModule { }
