import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';


import { SpinnerComponent } from './loading/spinner/spinner.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { CdkTableModule } from '@angular/cdk/table';
import { AuthAlertComponent } from './alerts/auth-alert/auth-alert.component';
import { PlaceholderDirective } from './placeholders/placeholder.directive';
import { SwipeCaptionComponent } from './swipe-caption/swipe-caption.component';
import { ValidatorService } from './services/staking/validator.service';
import { ImageSrcHandlerDirective } from './directives/image-src-handler.directive';
import { BookmarkHandlerDirective } from './directives/bookmark-handler.directive';
import { TothemoonSnackbarComponent } from './alerts/tothemoon-snackbar/tothemoon-snackbar.component';
import { SanitizerPipe } from './pipes/sanitizer.pipe';
import { ValidatorSortPipe } from './pipes/validator-sort.pipe';
import { DummyBoxComponent } from './placeholders/dummy-box/dummy-box.component';
import { ValidatorsHomeLoadingComponent } from './loading/validators-home-loading/validators-home-loading.component';
import { LoopReversePipe } from './pipes/loop-reverse.pipe';
import { TimestampAgoPipe } from './pipes/timestamp-ago.pipe';
import { TableSortPipe } from './pipes/table-sort.pipe';
import { CopyAddressHandlerDirective } from './directives/copy-address-handler.directive';


const COMPONENTS = [
  SpinnerComponent,
  AuthAlertComponent,
  PlaceholderDirective,
  SwipeCaptionComponent,
  ImageSrcHandlerDirective,
  BookmarkHandlerDirective,
  CopyAddressHandlerDirective,
  TothemoonSnackbarComponent,
  SanitizerPipe,
  ValidatorSortPipe,
  TableSortPipe,
  TimestampAgoPipe,
  ValidatorsHomeLoadingComponent,
  DummyBoxComponent,
  LoopReversePipe
]

const MaterialModules = [
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatToolbarModule,
  MatIconModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatTableModule,
  CdkTableModule,
  A11yModule,
  ClipboardModule,
  CdkStepperModule,
  CdkTreeModule,
  DragDropModule,
  MatAutocompleteModule,
  MatBottomSheetModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSliderModule,
  MatSnackBarModule,
  MatSortModule,
  MatTabsModule,
  MatTooltipModule,
  MatTreeModule,
  OverlayModule,
  PortalModule,
  ScrollingModule
]


@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    ChartsModule
  ],
  exports: [
    ...MaterialModules,
    COMPONENTS,
    ChartsModule
  ],
  providers: [
    ValidatorService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
  ]
})
export class SharedModule { }
