import { Component, OnInit, EventEmitter, Output, Input, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';
import { map } from 'rxjs/operators';
import * as AuthActions from 'src/app/shared/states/auth/auth.actions';
import { SearchModalComponent } from 'src/app/feature/search/search-modal/search-modal.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnChanges {


  @Output() burgerIconEvent = new EventEmitter();
  @Input() mobileIconsGroup: boolean;


  appName: string = 'Symphonie One';
  existsSellectionList: boolean = true;
  currentUser: string;

  navbarAct: boolean = false;

  isAuthenticated: boolean = false;
  private userSubcription: Subscription;


  constructor(private authService: AuthService,
              private store: Store<AppStore>,
              public dialog: MatDialog) { }


  navbarCtrl(value: boolean = false) {
    this.navbarAct = value;
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.navbarCtrl(changes.mobileIconsGroup.currentValue)
  }


  ngOnInit(): void {

    this.userSubcription = this.store.select('auth')
    .pipe(
      map(
        authState => {
          return authState.user
        }
      )
    )
    .subscribe(
      user => {
        this.isAuthenticated = !!user;
        // console.log(this.isAuthenticated);
        // console.log(user);
        this.currentUser = user ? user.id : null;
      },
      error => {
        // console.log(error);
        this.isAuthenticated = false;
      }
    );
  }


  onLogout() {
    // this.authService.logout();
    this.store.dispatch(new AuthActions.Logout());
  }

  ngOnDestroy() {
    if (this.userSubcription) {
      this.userSubcription.unsubscribe();
    }
  }


  openSearchModal() {
    this.dialog.open(SearchModalComponent, {
      width: '500px'
    })
  }


  toggleSidebar() {
    this.burgerIconEvent.emit();
  }

  renderScreenshot(el) {
    document.getElementById('output').appendChild(el);
  }

}
