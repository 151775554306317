import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ValidatorService } from './validator.service';
import { Validator, StakingTransactions, ValidatorPage } from '../../models/validator.model';
import { Store } from '@ngrx/store';
import { AppStore, selectValidator } from '../../states/app.reducer';
import { ValidatorActionTypes } from '../../states/validator/validator.actions';
import * as ValidatorActions from '../../states/validator/validator.actions';
import { take, tap, skipWhile, map, first, last, skip, mergeMap } from 'rxjs/operators';
import { Actions, ofType, Effect } from '@ngrx/effects';


@Injectable({
  providedIn: 'root'
})
// MOST IMPORTANT: RESOLVER ONLY SEND DATA JUST AFTER COMPLETE OBSERVABLE
// BUT IF USE STORE SELECT AS OBSERVABLE IT WONT COMPLETE SO RESOLVER WONT RETURN ANY DATA
// SO BY USING SELECT, WE MUST FINISH OBSERABLE BY USING first(), or take(1)
export class ValidatorResolverService implements Resolve<ValidatorPage> {

  constructor(private store: Store<AppStore>,
    private router: Router,
    private validatorSerive: ValidatorService,
    private actions$: Actions) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<ValidatorPage> | Promise<ValidatorPage> | ValidatorPage
  {
    const v = this.validatorSerive.getValidatorInformation(route.params['address']);
    const t = this.validatorSerive.getStakingTransactionsHistory(route.params['address']);

    return forkJoin({validator: v, transactions: t});
  }

  

    // return this.validatorSerive.getStakingTransactionsHistory(route.params['address']);
    // this.store.dispatch(
    //   new ValidatorActions.FetchValidatorStart(route.params['address'])
    // );

    // return this.actions$.pipe(
    //   ofType(ValidatorActionTypes.FETCH_VALIDATOR_SUCCESS),
    //   take(1)
    // )

    // // return this.store
    // //   .select(ValidatorSelectors.selectValidator, route.params['address'])
    // //   .pipe(
    // //     // skipWhile(
    // //     //   value => value.length == 0
    // //     // ),
    // //     skip(1),
    // //     take(1),
    // //     map(
    // //       result => {
    // //         console.log(result)
    // //         if (result.length > 0) {
    // //           return result
    // //         } else {
    // //           this.router.navigate(['/'])
    // //         }
    // //       }
    // //     )
    // //   )
  // }
  
}
