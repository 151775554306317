import { Injectable } from '@angular/core';
import { Subject, throwError, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BodyRaw } from '../../models/body-raw.model';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Network, NetworkResult, Epoch, NodeMetadataResult, NodeMetadata } from '../../models/network.model';
import { webSocket } from 'rxjs/webSocket';



@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  error = new Subject<string>();
  headers = new HttpHeaders();

  // total staking subject
  totalStakingValue: Array<number> = null;
  private totalStakingStatus = new BehaviorSubject(this.totalStakingValue);

  setTotalStakingSubject(last: number, total: number) {
    this.totalStakingStatus.next([ last, total]);
  }

  getTotalStakingSubject(): Observable<any> {
    return this.totalStakingStatus.asObservable();
  }


  // current block subject
  currentBlockHolder: Array<number> = [];
  private currentBlockCtrl = new BehaviorSubject(this.currentBlockHolder);

  setCurrentBlockSubject(duration: number, block: number, epoch: number) {
    this.currentBlockCtrl.next([duration, block, epoch])
  }

  getCurrentBlockSubject() {
    return this.currentBlockCtrl.asObservable();
  }


  // epoch state
  epochStateValue: Array<number> = null;
  private epochStateStatus = new BehaviorSubject(this.epochStateValue);

  setEpochStateSubject(last: number, epoch: number) {
    this.epochStateStatus.next([ last, epoch]);
  }

  getEpochStateSubject(): Observable<any> {
    return this.epochStateStatus.asObservable();
  }
  

  constructor(private http: HttpClient) { }

  getRealtimePrice() {
    return webSocket(environment.binanceWS);
  }



  getStakingNetworkInfo() {

    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: BodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getStakingNetworkInfo",
      params: [],
    }

    return this.http.post<Network>(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(responseData => {
        return responseData.result as NetworkResult;
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );
  }



  getNodeMetadata() {

    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: BodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getNodeMetadata",
      params: [],
    }

    return this.http.post<NodeMetadata>(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(responseData => {
        return responseData.result as NodeMetadataResult;
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );
  }



  getEpoch() {

    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: BodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getEpoch",
      params: [],
    }


    return this.http.post<Epoch>(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(responseData => {
        return responseData.result as number;
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );
  }


  getCurrentBlockNumber() {

    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: BodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_blockNumber",
      params: [],
    }

    return this.http.post<{jsonrpc: string, id: number, result: number}>(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(responseData => {
        return responseData.result as number;
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );
  }



  getNetworkStakePercentage() {

    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: BodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getCurrentUtilityMetrics",
      params: [],
    }

    return this.http.post(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );

  }


  getCurrentShardLeader(shardID: number) {
    
    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    let api: string;

    const postBody: BodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getLeader",
      params: [],
    }

    switch (shardID) {
      case 1:
        api = environment.harmonySRC1;
        break;
      case 2:
        api = environment.harmonySRC2;
        break;
      case 3:
        api = environment.harmonySRC3;
        break;
      default:
        api = environment.harmonySRC0;            
    }

    return this.http.post<{jsonrpc: string, id: number, result: string}>(
      api,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(responseData => {
        return {shard: shardID, validator: responseData.result};
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );

  }

}
