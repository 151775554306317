import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidatorComponent } from './validator.component';
import { SingleValidatorComponent } from './single-validator/single-validator.component';
import { ValidatorListComponent } from './validator-list/validator-list.component';
import { ValidatorExplorerComponent } from './validator-explorer/validator-explorer.component';
import { ValidatorResolverService } from 'src/app/shared/services/staking/validator-resolver.service';

const routes: Routes = [
  {
    path: 'validator',
    component: ValidatorComponent,
    children: [
      { path: '', component: ValidatorExplorerComponent },
      { path: 'all', component: ValidatorListComponent },
      {
        path: ':address',
        component: SingleValidatorComponent,
        resolve: { address: ValidatorResolverService }
      },
    ]
  }
];




@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ValidatorRoutingModule { }
