import { Action } from '@ngrx/store';


export enum AuthActionTypes {
    LOGIN_START = '[Auth] Login Start',
    AUTHENTICATE_SUCCESS = '[Auth] Authenticate Success',
    AUTHENTICATE_FAIL = '[Auth] Authenticate Fail',
    SIGNUP_START = '[Auth] Signup Start',
    SIGNUP_SUCCESS = '[Auth] Signup Success',
    CLEAR_ERROR = '[Auth] Clear Error',
    AUTO_LOGIN = '[Auth] Auto Login',
    LOGOUT = '[Auth] Logout'
}


export class LoginStart implements Action {
    readonly type = AuthActionTypes.LOGIN_START;

    constructor(
        public payload: {
            email: string,
            password: string
        }
    ) {}
}


export class SignupStart implements Action {
    readonly type = AuthActionTypes.SIGNUP_START;

    constructor(
        public payload: {
            email: string,
            password: string
        }
    ) {}
}


export class AuthenticateSuccess implements Action {
    readonly type = AuthActionTypes.AUTHENTICATE_SUCCESS;

    constructor(
        public payload: {
            email: string,
            userId: string,
            token: string,
            expirationDate: Date
        }
    ) {}
}


export class SignupSuccess implements Action {
    readonly type = AuthActionTypes.SIGNUP_SUCCESS;

    constructor(
        public payload: {
            email: string,
            userId: string,
            token: string,
            expirationDate: Date
        }
    ) {}
}



export class AuthenticateFail implements Action {
    readonly type = AuthActionTypes.AUTHENTICATE_FAIL;

    constructor(public payload: string) {}
}

export class Logout implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export class ClearError implements Action {
    readonly type = AuthActionTypes.CLEAR_ERROR;
}

export class AutoLogin implements Action {
    readonly type = AuthActionTypes.AUTO_LOGIN;
}




export type AuthActions = 
    | LoginStart
    | SignupStart
    | SignupSuccess
    | AuthenticateSuccess
    | AuthenticateFail
    | ClearError
    | AutoLogin
    | Logout;