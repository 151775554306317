import { FollowingActions, FollowingActionTypes } from './following.actions';


export interface State {
    followings: string[];
    isUpdated: boolean
}


const initialState: State = {
    followings: [],
    isUpdated: false
}


export function followingReducer(
    state = initialState,
    action: FollowingActions
) {

    // console.log(action.type)

    switch(action.type) {
        case FollowingActionTypes.LOAD_FOLLOWING_START:
            return {
                ...state,
                followings: [...state.followings],
                isUpdated: false
            }
        case FollowingActionTypes.LOAD_FOLLOWING_SUCCESS:
            return {
                ...state,
                followings: action.payload,
                isUpdated: true
            }
        case FollowingActionTypes.FLUSH_FOLLOWING:
            return {
                ...state,
                followings: [],
                isUpdated: false
            }
        default:
            return state;
    }
}