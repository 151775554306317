<div class="sidebar-container"
    [class.sidebar-is-mobile]="tabletQuery.matches"
    [ngClass]="tabletQuery.matches ? '' : ''"
    *ngIf="shouldRun">



    <mat-sidenav-container class="section-sidenav-container"
                           [style.marginTop.px]="tabletQuery.matches ? '0' : '56px'">

        <mat-sidenav #snav [mode]="tabletQuery.matches ? 'over' : 'side'"
                     [class.sidenav-is-mobile]="tabletQuery.matches"
                     [fixedInViewport]="tabletQuery.matches"
                     [fixedTopGap]="tabletQuery.matches ? '0' : '56'">
                     <!-- fixedTopGap="56" -->

            <mat-nav-list class="symphonie-padding-navlist">
                <!-- {{ mobileQuery.matches }}
                {{ user$.following }} -->
                <!-- <p *ngFor="let u of (user$ | async).user$">
                    tst
                    <span *ngFor="let f of u.following">{{ f }}</span>
                </p> -->

                <button mat-button
                        *ngIf="tabletQuery.matches && route.url != '/'"
                        class="sidebar-container__home-btn"
                        (click)="snav.close()"
                        routerLinkActive="active"
                        [routerLink]="['/']"
                        [routerLinkActiveOptions]="{exact: true}">

                        <span class="material-icons">home</span>
                </button>

                <i class="d-block px-3">Coming soon...</i>

                <div class="sidebar-container__timeline sidebar-container__timeline--empty"
                     *ngIf="!following">
                </div>


                <div id="timelineAccordion"
                     class="sidebar-container__timeline sidebar-container__timeline--active"
                     *ngIf="following">

                    <div class="card activity-status" *ngFor="let f of following; let i = index">
                        <div class="card-header activity-status__header" [id]="'heading' + i">
                            <h5 class="mb-0 headline"
                                    data-toggle="collapse"
                                    [attr.data-target]="'#collapse' + i"
                                    [attr.aria-controls]="'collapse' + i">

                                <i class="fas fa-circle elected-sign"
                                    [ngClass]="f['epos-status'] == 'currently elected' ? 'elected' : ''"
                                    [matTooltip]="f['epos-status'] == 'currently elected' ? 'Elected' : 'Not elected'">
                                </i>

                                {{ f.validator.name }}
                            </h5>
                        </div>
                  
                        <div [id]="'collapse' + i"
                             class="collapse activity-status__body"
                             [attr.aria-labelledby]="'heading' + i"
                             data-parent="#timelineAccordion">

                            <div class="card-body pt-2 px-1">

                                <span appCopyAddressHandler
                                      [cdkCopyToClipboard]="f.validator.address"
                                      class="wallet-address text-center d-block">

                                    {{ f.validator.address }}
                                </span>

                                <mat-divider class="my-4"></mat-divider>

                                <div class="content">
                                    safdfasdf asdfasdf
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                    
            </mat-nav-list>
        </mat-sidenav>


        <mat-sidenav-content>
            <div class="container-fluid main-wrapper mx-0 px-0">
            
                <app-nav-bar class="sidebar-toolbar"
                            [class.sidebar-is-mobile]="tabletQuery.matches"
                            [mobileIconsGroup]="tabletQuery.matches"
                            (burgerIconEvent)="snav.toggle()">
                </app-nav-bar>
            

                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>


    </mat-sidenav-container>
</div>

<div *ngIf="!shouldRun">Please enable Javascript!</div>