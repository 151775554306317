import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserComponent } from './user.component';
import { FollowingListComponent } from './following-list/following-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchModule } from '../search/search.module';



const COMPONENTS = [
  UserDashboardComponent,
  UserComponent,
  FollowingListComponent
]


@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    SharedModule,
    SearchModule,
    UserRoutingModule
  ],
  exports: COMPONENTS
})
export class UserModule { }
