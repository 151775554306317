import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ValidatorRoutingModule } from './validator-routing.module';
import { ValidatorComponent } from './validator.component';
import { SingleValidatorComponent } from './single-validator/single-validator.component';
import { ValidatorListComponent } from './validator-list/validator-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { SearchModule } from '../search/search.module';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';
import { SwiperModule } from 'swiper/angular';
import { ValidatorModalComponent } from './validator-modal/validator-modal.component';
import { ValidatorExplorerComponent } from './validator-explorer/validator-explorer.component';
import { ValidatorLeaderComponent } from './validator-leader/validator-leader.component';
import { FormsModule } from '@angular/forms';


const COMPONENTS = [
  ValidatorComponent,
  SingleValidatorComponent,
  ValidatorListComponent,
  ValidatorModalComponent,
  ValidatorExplorerComponent,
  ValidatorLeaderComponent
]

@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    SharedModule,
    DataTablesModule,
    ValidatorRoutingModule,
    SearchModule,
    SwiperModule,
    FormsModule
  ],
  exports: COMPONENTS
})
export class ValidatorModule { }
