import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf, Subscription } from 'rxjs';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';
import { Validator, Information } from 'src/app/shared/models/validator.model';
import { MatTableDataSource } from '@angular/material/table';
import { NetworkService } from 'src/app/shared/services/network/network.service';
import { map } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';



@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit, OnDestroy {


  validatorsSubscription: Subscription;
  networkSubscription: Subscription;
  private errorSubscription: Subscription;

  validators: Observable<{ validators: Validator[] }>;

  constructor(private store: Store<AppStore>,
              private validatorService: ValidatorService) {}


  ngOnInit(): void {

  }



  ngOnDestroy() {
    if (this.validatorsSubscription) {
      this.validatorsSubscription.unsubscribe();
    }

    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }

    if (this.networkSubscription) {
      this.networkSubscription.unsubscribe();
    }
  }

}
