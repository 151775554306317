import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-total-staking',
  templateUrl: './total-staking.component.html',
  styleUrls: ['./total-staking.component.scss']
})
export class TotalStakingComponent implements OnInit {


  @Input() totalStaking: number;

  
  constructor() { }

  ngOnInit(): void {
  }

}
