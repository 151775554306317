<div class="container validators-page" *ngIf="validators; else loadingPlaceholder">

    <app-search-bar class="validators-page__search-bar d-none d-md-block"
                    (onSelectedOption)="onSelectedFilter($event)"
                    [allValidators]="searchOutput">
    </app-search-bar>



    <div class="validators-page__elected-section">

        <div class="title title-text">
            <!-- <a routerLink="design">Elected</a> -->
            <h1 *ngIf="!isSearching">Elected Validators</h1>
            <h1 *ngIf="isSearching">Search result</h1>
        </div>

        <app-swipe-caption *ngIf="tabletQuery.matches && !isSearching && validators"></app-swipe-caption>

        <div class="list-wrapper">
            
            <div class="elected-slider" *ngIf="!isSearching">

                <p *ngIf="!validators">No validator available!</p>
                <div *ngIf="validators" class="elected-slider__wrp swiper-wrapper">

                    <swiper (window:resize)="breakpointChange()"
                            class="elected-slider__container"
                            [pagination]="{ clickable: true, type: 'fraction' }"
                            [breakpoints]="breakpoints"
                            [navigation]="!tabletQuery.matches">

                        <ng-template class="elected-slider__item swiper-slide"
                                    *ngFor="let v of validators | validatorSort: 'uptime'"
                                    swiperSlide>
                            
                            <a href="javascript:void(0)" (click)="openValidatorModal(v)"
                                class="validator-link">

                                <div class="validator-link__headline">
                                    <p>Uptime AVG <strong>{{ v.uptime30AVG | number: '1.2-2' }}%</strong></p>
                                    <!-- <span class="material-icons">turned_in_not</span> -->
                                </div>

                                <!-- <div class="validator-link__img"
                                    [style.background-image]="'url(' + generateAvatar(v.address) + ')'"> -->
                                <div class="validator-link__img">
                                    <!-- <img [src]="des.bannerPath" [alt]="des.title"> -->
                                    <img appImageSrcHandler
                                        [src]="generateAvatar(v.address)"
                                        default="../../../assets/images/hmny-logo-avatar.png"
                                        class="img-bg">

                                    <img appImageSrcHandler
                                        [src]="generateAvatar(v.address)"
                                        default="../../../assets/images/hmny-logo-avatar.png"
                                        class="img">
                        
                                </div>

                                <div class="validator-link__footer">
                                    <div class="percentage">
                                        <p class="percentage__er">Latest ER <strong>{{ v.latestExpectedReturn | number: '1.2-2' }}%</strong></p>
                                        <p class="percentage__fee">Fee <strong>{{ v.fee | number: '1.2-2' }}%</strong></p>
                                    </div>    
                                    <p class="total-staked">Total Staked <strong>{{ v.totalStake | number: '1.0-0' }}</strong></p>
                                </div>

                            </a>

                            <strong class="validator-link__title mt-3">
                                {{ v.name }}
                            </strong>

                        </ng-template>
                    </swiper>

                </div>
                        
            </div>

            <div class="search-result" *ngIf="isSearching">
                <div class="elected-slider__item search-result__wrapper mb-4" *ngFor="let val of searchOutput">

                    <a href="javascript:void(0)"
                        [routerLink]="['/validator', val.address]"
                        class="validator-link">

                        <div class="search-result__thumb">

                            <strong class="teaser-headline mb-3">
                                {{ val.name }}
                                <!-- <span class="material-icons">turned_in_not</span> -->
                            </strong>

                            <mat-divider></mat-divider>

                        </div>



                        <div class="search-result__details">

                            <div class="validator-link__img teaser-logo">
                                <img appImageSrcHandler
                                    [src]="generateAvatar(val.address)"
                                    default="../../../assets/images/hmny-logo-avatar.png"
                                    class="img-bg">
    
                                <img appImageSrcHandler
                                    [src]="generateAvatar(val.address)"
                                    default="../../../assets/images/hmny-logo-avatar.png"
                                    class="img">
                            </div>


                            <div class="summary row">

                                <div class="pill-box pr-sm-0">
                                    <small class="pill-box__headline">
                                        Uptime Average
                                    </small>
                                    <p>{{ (val.uptime30AVG | number: '1.2-2') + '%' }}</p>
                                </div>

                                <div class="pill-box pr-sm-0">
                                    <small class="pill-box__headline">
                                        Status
                                    </small>
                                    <p [ngClass]="val.status == 'Elected' ? 'elected' : 'not-elected'">
                                        {{ val.status }}
                                    </p>
                                </div>

                                <div class="pill-box pr-sm-0">
                                    <small class="pill-box__headline">
                                        Fee
                                    </small>

                                    <p>{{ (val.fee | number: '1.2-2') + '%' }}</p>
                                </div>

                    

                                <div class="pill-box pr-sm-0">
                                    <small class="pill-box__headline">
                                        Expected Return
                                    </small>

                                    <p>{{ (val.latestExpectedReturn | number: '1.2-2') + '%' }}</p>
                                </div>

                                <div class="pill-box pr-sm-0">
                                    <small class="pill-box__headline">
                                        Max Fee Change
                                    </small>

                                    <p>{{ (val.maxFeeChange | number: '1.2-2') + '%' }}</p>
                                </div>

                                <div class="pill-box pr-sm-0">
                                    <small class="pill-box__headline">
                                        Total Staked
                                    </small>

                                    <p>{{ val.totalStake | number: '1.0-0' }}</p>
                                </div>

                    
                            </div>
                        </div>


                    </a>

                    <div class="validator-percentage mb-sm-3 mt-3 mt-sm-5">
                        <span>Current signing percentage</span>
                        <div class="signing-percentage-desktop validator-percentage__progress my-2"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            [title]="percentageTooltip(+val.currentEpochSigningPercent)"
                            [style.opacity]="+(val.currentEpochSigningPercent | number: '1.2-2') == 0 ? '.5' : '1'">
                    
                            <mat-progress-bar mode="determinate"
                                            [value]="+(val.currentEpochSigningPercent | number: '1.2-2')">
                            </mat-progress-bar>
                        </div>
                        <span *ngIf="val.status == 'Elected'">{{ val.currentEpochSigningPercent | number: '1.2-2' }}%</span>
                        <span *ngIf="val.status != 'Elected'">00.00%</span>
                    </div>
    
                </div>


            </div>
            
        </div>    
    

    </div>


    <div class="validators-page__general-validators mt-3 mb-5">
        <div class="description-box">
            <h2 class="description-box__title">
                All-in-One
            </h2>
            <p class="description-box__text">
                Enhance assessment and<br>reach better conclusions faster
            </p>
            <button class="btn btn-light" [routerLink]="['/validator', 'all']">Discover</button>
        </div>
    </div>


    <div class="validators-page__categories-section my-md-5">

        <div class="title title-text">
            <h1 class="DYOR">Have Perspective</h1>
            <small class="note">
                <span class="note--elected">&#8226;</span>&nbsp;<i>Elected,</i>&nbsp;&nbsp;<span>&#8226;</span>&nbsp;<i>Not elected</i>
            </small>    
        </div>


        <p *ngIf="!validators">No validator available!</p>
        <mat-tab-group *ngIf="validators"
                        class="categories-wrapper"
                        style="min-height: 450px;"
                        dynamicHeight>

            <!-- Total Staked -->
            <mat-tab label="Total Staked" class="categories-wrapper__tab">
                <div class="categories-wrapper__content-box categories-wrapper__content-box--fee mat-elevation-z4 row">

                    <mat-paginator [length]="dyorPaginatorLenght"
                                    [pageSize]="dyorPaginatorSize"
                                    (page)="onPageChange($event, 'staked')"
                                    [hidePageSize]="true"
                                    class="categories-wrapper__paginator">
                    </mat-paginator>

                    <!-- <a [routerLink]="['/validator', t.address]"
                        class="validator-summary col-12 col-sm-6 col-md-4 col-lg-3"
                        *ngFor="let t of validators | validatorSort: 'staked'"> -->
                    <a href="javascript:void(0)" (click)="openValidatorModal(v)"
                        class="validator-summary col-12 col-sm-6 col-md-4 col-lg-3"
                        *ngFor="let v of currentStakedList">
                        
                        <div class="validator-summary__headline">
                            <span class="validator-summary__data">
                                Total Staked {{ v.totalStake | number: '1.0-0' }}
                            </span>
                        </div>

                        
                        <h4 [ngClass]="v.status == 'Elected' ? 'elected' : ''"
                            class="validator-summary__name">
                            <span class="election-light">&#8226;</span>
                            {{ v.name }}
                        </h4>
                    </a>
                </div>
            </mat-tab>


            <!-- Return -->
            <mat-tab label="Latest Expected Return" class="categories-wrapper__tab">
                <div class="categories-wrapper__content-box categories-wrapper__content-box--fee mat-elevation-z4 row">
                    
                    <mat-paginator [length]="dyorPaginatorLenght"
                                    [pageSize]="dyorPaginatorSize"
                                    (page)="onPageChange($event, 'return')"
                                    [hidePageSize]="true"
                                    class="categories-wrapper__paginator">
                    </mat-paginator>

                    
                    <a href="javascript:void(0)" (click)="openValidatorModal(v)"
                        class="validator-summary col-12 col-sm-6 col-md-4 col-lg-3"
                        *ngFor="let v of currentERList">
                        
                        <div class="validator-summary__headline">
                            <span class="validator-summary__data">Latest ER {{ v.latestExpectedReturn | number: '1.2-2' }}%</span>
                        </div>

                        <h4 [ngClass]="v.status == 'Elected' ? 'elected' : ''"
                            class="validator-summary__name">
                            <span class="election-light">&#8226;</span>
                            {{ v.name }}
                        </h4>
                    </a>
                </div>
            </mat-tab>


            <!-- Fee -->
            <mat-tab label="Fee" class="categories-wrapper__tab">
                <div class="categories-wrapper__content-box categories-wrapper__content-box--fee mat-elevation-z4 row">
                    
                    
                    <mat-paginator [length]="dyorPaginatorLenght"
                                    [pageSize]="dyorPaginatorSize"
                                    (page)="onPageChange($event, 'fee')"
                                    [hidePageSize]="true"
                                    class="categories-wrapper__paginator">
                    </mat-paginator>

                    
                    <a href="javascript:void(0)" (click)="openValidatorModal(v)"
                        class="validator-summary col-12 col-sm-6 col-md-4 col-lg-3"
                        *ngFor="let v of currentFeeList">
                        
                        <div class="validator-summary__headline">
                            <span class="validator-summary__data">Fee {{ v.fee | number: '1.2-2' }}%</span>
                            <!-- <span class="badge ml-2" [ngClass]="v.status == 'Elected' ? 'bg-secondary' : 'badge-outline-dark'">
                                {{ v.status }}
                            </span> -->
                        </div>
<!-- 
                        <h4 [matBadge]="v.status == 'Elected' ? 'e' : '&#8226;'"
                            matBadgeOverlap="true"
                            matBadgePosition="before"
                            [ngClass]="v.status == 'Elected' ? 'elected' : ''"
                            [matBadgeColor]="v.status == 'Elected' ? 'primary' : 'warn'"
                            class="validator-summary__name">

                            {{ v.name }}
                        </h4> -->
                        <h4 [ngClass]="v.status == 'Elected' ? 'elected' : ''"
                            class="validator-summary__name">
                            <span class="election-light">&#8226;</span>
                            {{ v.name }}
                        </h4>
                    </a>
                </div>
            </mat-tab>


        </mat-tab-group>
          
    </div>

</div>

<ng-template #loadingPlaceholder>
    <app-validators-home-loading></app-validators-home-loading>
</ng-template>