import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableData } from 'src/app/shared/models/validator.model';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-validator-modal',
  templateUrl: './validator-modal.component.html',
  styleUrls: ['./validator-modal.component.scss']
})
export class ValidatorModalComponent implements OnInit {


  authSubcription: Subscription;
  isAuthenticated: boolean = false;

  
  constructor(public dialogRef: MatDialogRef<ValidatorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TableData,
              private _store: Store<AppStore>) {
                
                this.authSubcription = this._store.select('auth').subscribe(
                  auth => {
                    if (!!auth.user) {
                      this.isAuthenticated = true
                    }
                  }
                )

              }


  ngOnInit(): void { }

  onNoClick(): void{
    this.dialogRef.close();
  }

}
