import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-validators-home-loading',
  templateUrl: './validators-home-loading.component.html',
  styleUrls: ['./validators-home-loading.component.scss']
})
export class ValidatorsHomeLoadingComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;


  constructor(media: MediaMatcher,
              cd: ChangeDetectorRef) {

                this.mobileQuery = media.matchMedia('(max-width: 767px)');
                this._mobileQueryListener = () => cd.detectChanges();
                this.mobileQuery.addListener(this._mobileQueryListener);

              }

  ngOnInit(): void {}

}
