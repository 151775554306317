import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TotalStakingComponent } from './total-staking.component';



@NgModule({
  declarations: [
    TotalStakingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TotalStakingComponent
  ],
})
export class TotalStakingModule { }
