import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampAgo'
})
export class TimestampAgoPipe implements PipeTransform {

  transform(value: number): string {
    const current = Date.now();

    if (value) {
      const sec = Math.floor( (current / 1000) - value);

      if (sec < 29) {
        return 'Just now';
      }
      const timing = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      }
      let counter;

      for( const i in timing) {
        counter = Math.floor(sec / timing[i]);

        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + ' ago';
          } else {
            return counter + ' ' + i + 's ago';
          }  
        }
      }
    }
    return '<none>';


  }

}
