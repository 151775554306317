





<div class="shard-leaders-wrapper row" *ngIf="leader.length == 4">
    <div *ngFor="let l of leader"
          class="shard-leaders-wrapper__leader-box shadow-sm p-3 rounded">
        {{ l.validator }}
    </div>
</div>
