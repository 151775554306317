import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-auth-alert',
  templateUrl: './auth-alert.component.html',
  styleUrls: ['./auth-alert.component.scss']
})
export class AuthAlertComponent {

  @Input() message: string;
  @Output() close = new EventEmitter<void>();


  constructor() { }

  onClose() {
    this.close.emit();
  }
}
