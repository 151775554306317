import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NetworkService } from 'src/app/shared/services/network/network.service';
import { take, tap, map, skipWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';
import * as ValidatorSelectors from '../../../shared/states/app.reducer';
import { ValidatorActionTypes } from '../../../shared/states/validator/validator.actions';
import * as ValidatorActions from '../../../shared/states/validator/validator.actions';
import { Validator } from 'src/app/shared/models/validator.model';


export interface Leaders {
  shard: number,
  validator: string
}

@Component({
  selector: 'app-validator-leader',
  templateUrl: './validator-leader.component.html',
  styleUrls: ['./validator-leader.component.scss']
})
export class ValidatorLeaderComponent implements OnInit, OnDestroy {

  networkLeader0: Subscription
  networkLeader1: Subscription
  networkLeader2: Subscription
  networkLeader3: Subscription
  validatorInfo: Subscription

  shard0Leader: Leaders = null;
  shard1Leader: Leaders = null;
  shard2Leader: Leaders = null;
  shard3Leader: Leaders = null;

  leader: Array<Leaders>= []


  constructor(private networkService: NetworkService,
              private store: Store<AppStore>) { }



  ngOnInit(): void {


    // this.store
    // .select(ValidatorSelectors.selectValidator, 'one1gh043zc95e6mtutwy5a2zhvsxv7lnlklkj42ux')
    // .pipe(
    //   tap(
    //     v => console.log(v)
    //   ),
    //   skipWhile(
    //     value => value.length == 0
    //   ),
    // )
    // .subscribe(
      
    //   validator => {
    //     return validator
    //   }
    // )



    this.networkLeader0 = this.networkService.getCurrentShardLeader(0)
    .pipe(
      tap(
        v => console.log(v)
      ),
      map(
        v => {
          if (v.validator) {
            return v
          }
        }
      )
    )
    .subscribe(
      response => {
        this.leader[0] = response
      }
    )



    this.networkLeader1 = this.networkService.getCurrentShardLeader(1)
    .pipe(
      tap(
        v => console.log(v)
      ),
      map(
        v => {
          if (v.validator) {
            return v
          }
        }
      )
    )
    .subscribe(
      response => this.leader[1] = response
    )



    this.networkLeader2 = this.networkService.getCurrentShardLeader(2)
    .pipe(
      tap(
        v => console.log(v)
      ),
      map(
        v => {
          if (v.validator) {
            return v
          }
        }
      )
    )
    .subscribe(
      response => this.leader[2] = response
    )

    this.networkLeader3 = this.networkService.getCurrentShardLeader(3)
    .pipe(
      tap(
        v => console.log(v)
      ),
      map(
        v => {
          if (v.validator) {
            return v
          }
        }
      )
    )
    .subscribe(
      response => this.leader[3] = response
    )




  }





  ngOnDestroy(): void{
    if (this.networkLeader0) {
      this.networkLeader0.unsubscribe();
    }

    if (this.networkLeader1) {
      this.networkLeader1.unsubscribe();
    }

    if (this.networkLeader2) {
      this.networkLeader2.unsubscribe();
    }

    if (this.networkLeader3) {
      this.networkLeader3.unsubscribe();
    }

  }


}
