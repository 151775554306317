import { Directive, ElementRef, HostListener, Input, Renderer2, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStore } from '../states/app.reducer';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthComponent } from 'src/app/feature/auth/auth.component';



@Directive({
  selector: '[appBookmarkHandler]'
})
export class BookmarkHandlerDirective implements OnInit {


  @Input() currentFollowingList: string[];
  @Input() validatorAddress: string;
  @Input() isAuthenticated: boolean;

  // @Output() isFollowed = new EventEmitter();
  @Output() modalStatus = new EventEmitter();
  
  followingSubscription: Subscription;
  authSubcription: Subscription;
  following: string[] = [];


  constructor(private _el: ElementRef,
              private _renderer: Renderer2,
              private _store: Store<AppStore>,
              public dialog: MatDialog) { }




  public ngOnInit() {

    if (this.isAuthenticated) {
      this.followingSubscription = this._store.select('followingsList').subscribe(

        following => {
          this.following = following.followings
  
          if (this.following.includes(this.validatorAddress)) {
            this._renderer.setProperty(this._el.nativeElement, 'innerHTML', 'bookmark_remove');
            this._renderer.addClass(this._el.nativeElement, 'following');
          } else {
            this._renderer.addClass(this._el.nativeElement, 'not-following');
          }

        }
  
      )  
    }

  }




  @HostListener('click') onClick() {
    // console.log(this._el)

    if (this.isAuthenticated) {

      if (!this.following.includes(this.validatorAddress)) {

        this._renderer.setProperty(this._el.nativeElement, 'innerHTML', 'bookmark_remove');
        this._renderer.addClass(this._el.nativeElement, 'following');
        // add to following list
      } else {

        this._renderer.setProperty(this._el.nativeElement, 'innerHTML', 'turned_in_not');
        this._renderer.removeClass(this._el.nativeElement, 'following');
        // remove from following list
      }

    } else {

      this.modalStatus.emit();

      this.dialog.open(AuthComponent, {
        width: '600px',
        data: true // true to introduce this request as a popup instead page
      })
  
    }

  }


  ngOnDestroy(): void{
    if (this.followingSubscription) {
      this.followingSubscription.unsubscribe();
    }
  }

}

