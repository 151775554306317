import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-validator',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}

}