import { Validator } from '../../models/validator.model';
import { ValidatorActionTypes, ValidatorActions } from './validator.actions';


export interface State {
    validators: Validator[];
    isUpdated: boolean
    // editedIngredient: Ingredient;
    // editedIngredientIndex: number;
}

// const initialState = {
//     validators: [],
//     isLoading: true
// }

// const initialState: State = {
//     ingredients: [
//         new Ingredient('apple', 5),
//         new Ingredient('tomato', 4)
//     ],
//     // editedIngredient: null,
//     // editedIngredientIndex: -1
// }
const initialState: State = {
    validators: [],
    isUpdated: false
}



export function validatorReducer(
    state = initialState,
    action: ValidatorActions
) {
    switch(action.type) {
        case ValidatorActionTypes.LOAD_VALIDATORS_START:
            return {
                ...state,
                validators: [...state.validators],
                isUpdated: false
            }
        case ValidatorActionTypes.LOAD_VALIDATORS_SUCCESS:
            return {
                ...state,
                // validators: [...state.validators, action.payload],
                validators: action.payload,
                isUpdated: true
                // isLoading: false
                // editedIngredient: null,
                // editedIngredientIndex: -1
            }

        default:
            return state;
    }
}




export interface singleState {
    validator: Validator;
    isLoaded: boolean
}


const initialSingleState: singleState = {
    validator: null,
    isLoaded: false
}

export function singleReducer(
    state = initialSingleState,
    action: ValidatorActions
) {
    switch(action.type) {
        case ValidatorActionTypes.FETCH_VALIDATOR_START:
            return {
                ...state,
                validator: {...state.validator},
                isLoaded: false
            }
        case ValidatorActionTypes.FETCH_VALIDATOR_SUCCESS:
            return {
                ...state,
                // validators: [...state.validators, action.payload],
                validator: action.payload,
                isLoaded: true
            }
        case ValidatorActionTypes.FETCH_VALIDATOR_FAIL:
            return {
                ...state,
                validator: null,
                isLoaded: false
            }
    
        default:
            return state;
    }
}

