import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';
import { TableData } from 'src/app/shared/models/validator.model';
import { Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';
import { skipWhile, take, map } from 'rxjs/operators';
import * as ValidatorActions from 'src/app/shared/states/validator/validator.actions';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
} from "swiper/core";
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ValidatorModalComponent } from '../validator-modal/validator-modal.component';
import { environment } from 'src/environments/environment';

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);


@Component({
  selector: 'app-validator-explorer',
  templateUrl: './validator-explorer.component.html',
  styleUrls: ['./validator-explorer.component.scss']
})
export class ValidatorExplorerComponent implements OnInit, OnDestroy {

  validators: TableData[];
  searchOutput: TableData[];
  currentStakedList: TableData[];
  currentERList: TableData[];
  currentFeeList: TableData[];

  validatorsSubscription: Subscription;
  isFetching: boolean = true;
  isSearching: boolean = false;
  error = null;
  dtTrigger: Subject<any> = new Subject<any>();
  tabletQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  pagination: any = false;
  dyorPaginatorLenght: number;
  dyorPaginatorSize: number = 12;


  breakpoints = {
    320: { slidesPerView: 1, spaceBetween: 20 },
    768: { slidesPerView: 1.6, spaceBetween: 30 },
    1024: { slidesPerView: 2.6, spaceBetween: 40 },
    1200: { slidesPerView: 3.6, spaceBetween: 40 }
  };

  constructor(private _validatorService: ValidatorService,
              private _store: Store<AppStore>,
              cd: ChangeDetectorRef,
              public dialog: MatDialog,
              media: MediaMatcher) {

                this.tabletQuery = media.matchMedia(environment.tabletSize);
                this._mobileQueryListener = () => cd.detectChanges();
                this.tabletQuery.addListener(this._mobileQueryListener);

              }

  ngOnInit(): void {
    
    this.validatorsSubscription = this._store.select('validatorsList')
    .pipe(
      skipWhile(
        val => val.validators.length == 0
      ),
      take(1),
      map(
        res => {
          let eligibleValidators = [];

          res.validators.forEach( validator => {
            if (validator['active-status'] === 'active') {
              eligibleValidators.push(validator);
            }
          })

          return eligibleValidators;
        }
      ),
    )
    .subscribe(
      responseData => {
        // console.log(responseData)
        const validatorsList: TableData[] = [];

        let networkTotalStake: number = 0;

        for (const key in responseData) {

          const lifetimeEpochInfo = responseData[key].lifetime['epoch-apr'] != null ? responseData[key].lifetime['epoch-apr'] : [];
          const lifetimeBlocksReport = responseData[key].lifetime['epoch-blocks'];
          const currentEpochPerformance = responseData[key]['current-epoch-performance'];

          const tempData: TableData = {
            id: responseData[key].id,
            status: responseData[key]['epos-status'] === 'currently elected' ? 'Elected' : 'Not elected',
            name: responseData[key].validator.name,
            website: responseData[key].validator.website,
            details: responseData[key].validator.details,
            creationHeight: responseData[key].validator['creation-height'],
            lastEpochCommittee: responseData[key].validator['last-epoch-in-committee'],
            address: responseData[key].validator.address,
            totalStake: responseData[key]['total-delegation'] / 1e18,
            stakeWeight: '0',
            fee: (+responseData[key].validator.rate * 100).toString(),
            maxFee: (+responseData[key].validator['max-rate'] * 100).toString(),
            maxFeeChange: (+responseData[key].validator['max-change-rate'] * 100).toString(),
            latestExpectedReturn: (lifetimeEpochInfo.length != 0) ? (+lifetimeEpochInfo[lifetimeEpochInfo.length - 1].apr * 100).toString() : '0',
            lifetimeRewards: responseData[key].lifetime['reward-accumulated'] / 1e18,
            uptimeAllAVG: (responseData[key].lifetime['blocks']['signed'] * 100) / responseData[key].lifetime['blocks']['to-sign'],
            uptime30AVG: (lifetimeBlocksReport != null) ? this.calculate30SignesAVG(lifetimeBlocksReport) : 0,
            currentEpochSigningPercent: (currentEpochPerformance != null) ? (+currentEpochPerformance['current-epoch-signing-percent']['current-epoch-signing-percentage'] * 100).toString() : null,
          }

          validatorsList.push(tempData);
          networkTotalStake += responseData[key]['total-delegation'];  
        }

        for (const key in validatorsList) {
          validatorsList[key].stakeWeight = this._validatorService.generateStakeWeight(validatorsList[key].totalStake, networkTotalStake / 1000000000000000000);
        }

        this.dyorPaginatorLenght = validatorsList.length
        this.validators = validatorsList
        this.searchOutput = validatorsList
        this._validatorService.validatorsData = validatorsList

        this.currentStakedList = validatorsList
          .sort( (a, b) => a['totalStake'] > b['totalStake'] ? -1 : 1 )
          .slice(0, this.dyorPaginatorSize)

        this.currentFeeList = validatorsList
          .sort( (a, b) => +a['fee'] < +b['fee'] ? -1 : 1 )
          .slice(0, this.dyorPaginatorSize)

        this.currentERList = validatorsList
          .sort( (a, b) => +a['latestExpectedReturn'] > +b['latestExpectedReturn'] ? -1 : 1 )
          .slice(0, this.dyorPaginatorSize)

      },
      error => {
        this.isFetching = false;
        this.error = error.message;
      }
    )
    .add(
      () => {
        this.dtTrigger.next();
        this.isFetching = false;
      }
    )

  }

  calculate30SignesAVG(value) {

    let sum = 0;
    for( let i = 0; i < value.length; i++) {
        // console.log(value[i].blocks)
      if (value[i].blocks['signed'] == 0 || value[i].blocks['to-sign'] == 0){
        sum += 0
      } else {
        sum += (value[i].blocks['signed'] * 100) / value[i].blocks['to-sign']
      }
    }
    // console.log('uptime: ' + sum / value.length)
    return sum / value.length;
  }

  onPageChange(event, filter: string) {
    if (filter === 'staked') {
      this.currentStakedList = this.validators
        .sort( (a, b) => a['totalStake'] > b['totalStake'] ? -1 : 1 )
        .slice(event.pageIndex * event.pageSize, event.pageIndex * event.pageSize + event.pageSize)
    
    } else if (filter === 'fee') {
      this.currentFeeList = this.validators
        .sort( (a, b) => +a['fee'] < +b['fee'] ? -1 : 1 )
        .slice(event.pageIndex * event.pageSize, event.pageIndex * event.pageSize + event.pageSize)
    
    } else if (filter === 'return') {
      this.currentERList = this.validators
        .sort( (a, b) => +a['latestExpectedReturn'] > +b['latestExpectedReturn'] ? -1 : 1 )
        .slice(event.pageIndex * event.pageSize, event.pageIndex * event.pageSize + event.pageSize)
    }
  }

  onLoadValidator(address: string) {
    this._store.dispatch(new ValidatorActions.FetchValidatorStart(address));
  }

  openValidatorModal(validator: TableData) {
    this.dialog.open(ValidatorModalComponent, {
      width: '500px',
      data: validator
    })
  }

  breakpointChange() {
    this.breakpoints = {
      320: { slidesPerView: 1, spaceBetween: 20 },
      768: { slidesPerView: 1.6, spaceBetween: 30 },
      1024: { slidesPerView: 2.6, spaceBetween: 40 },
      1200: { slidesPerView: 3.6, spaceBetween: 40 }
    };
  }

  onSelectedFilter(e) {
    // console.log(e)
    this.getFilteredExpenseList();

    if (e.length > 0 ) {
      this.isSearching = true;
    } else {
      this.isSearching = false;
    }
  }


  percentageTooltip(percent: number) {
    if (percent == 0) {
      return 'Currently not elected';
    } else {
      return 'Current signing percent: ' + percent.toFixed(2) + '%';
    }
  }

  getFilteredExpenseList() {
    if (this._validatorService.searchOption.length > 0) {
      // this.validators = this.validatorService.filteredListOptions();
      this.searchOutput = this._validatorService.filteredListOptions();
    } else {
      // this.validators = this.validatorService.validatorsData;
      this.searchOutput = this._validatorService.validatorsData;
    }
  }


  generateAvatar(address: string) {
    return this._validatorService.getValidatorAvatar(address);
  }

  putDefaultAvatar() {
    return '../../../assets/images/hmny-logo-avatar.png';
  }


  ngOnDestroy() {
    if (this.validatorsSubscription) {
      this.validatorsSubscription.unsubscribe();
    }

    this.tabletQuery.removeListener(this._mobileQueryListener);
  }

}