import { User } from '../../models/user.model';
import { AuthActions, AuthActionTypes } from './auth.actions';


export interface State {
    user: User;
    authError: string;
    loading: boolean;
}


const initialState: State = {
    user: null,
    authError: null,
    loading: false
};



export function authReducer(
    state = initialState,
    action: AuthActions
) {
    switch(action.type) {
        case AuthActionTypes.LOGIN_START:
        case AuthActionTypes.SIGNUP_START:
            return {
                ...state,
                authError: null,
                loading: true
            }
        // AUTHENTICATE stands for signup and login
        case AuthActionTypes.AUTHENTICATE_SUCCESS:
        case AuthActionTypes.SIGNUP_SUCCESS:
            const user = new User(
                action.payload.email,
                action.payload.userId,
                action.payload.token,
                action.payload.expirationDate
            );
            return {
                ...state,
                user: user,
                authError: null,
                loading: false
            }
        case AuthActionTypes.AUTHENTICATE_FAIL:
            return {
                ...state,
                user: null,
                authError: action.payload,
                loading: false
            }
        case AuthActionTypes.CLEAR_ERROR:
            return {
                ...state,
                authError: null
            }
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                user: null
            }
        default:
            return state;
    }
}