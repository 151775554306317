import { Action } from '@ngrx/store';


export enum FollowingActionTypes {
    LOAD_FOLLOWING_START = '[Following] Loading Start',
    LOAD_FOLLOWING_SUCCESS = '[Following] Loading Success',
    LOAD_FOLLOWING_FAIL = '[Following] Loading Fail',

    ADD_FOLLOWING_CALL = '[Following] Follow Validator Call',
    ADD_FOLLOWING_SUCCESS = '[Following] Follow Validator Success',
    ADD_FOLLOWING_FAIL = '[Following] Follow Validator Fail',

    REMOVE_FOLLOWING_CALL = '[Following] Unfollow Validator Call',
    REMOVE_FOLLOWING_SUCCESS = '[Following] Unfollow Validator Success',
    REMOVE_FOLLOWING_FAIL = '[Following] Unfollow Validator Fail',
    
    FLUSH_FOLLOWING = '[Following] Flush List',
}

// fires a request through ngrx environment to say it load a user following list
export class LoadFollowingStart implements Action {
    readonly type = FollowingActionTypes.LOAD_FOLLOWING_START;

    // get the srign of user id once we fire this action
    constructor(public payload: string) {}
}


// fires a request with carry an array of users following
export class LoadFollowingSuccess implements Action {
    readonly type = FollowingActionTypes.LOAD_FOLLOWING_SUCCESS;

    // will carry the following array data as its payload and fire through store enviromnet as end of actions call
    constructor(public payload: string[]) {}
}

// fires a request with error message
export class LoadFollowingFail implements Action {
    readonly type = FollowingActionTypes.LOAD_FOLLOWING_FAIL;

    constructor(public payload: string) {}
}




export class AddFollowingCall implements Action {
    readonly type = FollowingActionTypes.ADD_FOLLOWING_CALL;

    // request to store and backend to remove a package of followings
    constructor(public payload: string[]) {}
}

export class AddFollowingSuccess implements Action {
    readonly type = FollowingActionTypes.ADD_FOLLOWING_SUCCESS;

    // call request accepted and done and responses an array of current followings
    constructor(public payload: string[]) {}
}

export class AddFollowingFail implements Action {
    readonly type = FollowingActionTypes.ADD_FOLLOWING_FAIL;

    constructor(public payload: string) {}
}





export class RemoveFollowingCall implements Action {
    readonly type = FollowingActionTypes.REMOVE_FOLLOWING_CALL;

    constructor(public payload: string[]) {}
}

export class RemoveFollowingSuccess implements Action {
    readonly type = FollowingActionTypes.REMOVE_FOLLOWING_SUCCESS;

    constructor(public payload: string[]) {}
}

export class RemoveFollowingFail implements Action {
    readonly type = FollowingActionTypes.REMOVE_FOLLOWING_FAIL;

    constructor(public payload: string) {}
}


export class FlushFollowing implements Action {
    readonly type = FollowingActionTypes.FLUSH_FOLLOWING;

    constructor() {}
}





export type FollowingActions
    = LoadFollowingStart
    | LoadFollowingSuccess
    | LoadFollowingFail
    | AddFollowingCall
    | AddFollowingSuccess
    | AddFollowingFail
    | RemoveFollowingCall
    | RemoveFollowingSuccess
    | RemoveFollowingFail
    | FlushFollowing;