import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaMatcher } from '@angular/cdk/layout';
import { Subscription, Observable } from 'rxjs';
import * as fromFollowing from '../../shared/states/following/following.reducer';
import { Store } from '@ngrx/store';
import { AppStore, selectValidator } from 'src/app/shared/states/app.reducer';
import { take, skip, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as ValidatorSelectors from '../../shared/states/app.reducer';
import { Validator } from 'src/app/shared/models/validator.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy {

  @ViewChild('snav') public sidenav: MatSidenav;

  // subscriptionSidebar: Subscription;
  authSubcription: Subscription;
  followingSubscription: Subscription;
  selectorSubscription: Subscription;

  shouldRun: boolean = true;
  isHome: boolean = true;

  tabletQuery: MediaQueryList;

  following: Validator[] = [];

  private _mobileQueryListener: () => void;



  constructor(changeDetectorRef: ChangeDetectorRef,
              private _store: Store<AppStore>,
              private route: Router,
              private _auth: AuthService,
              media: MediaMatcher) {
                
                // this.mobileQuery = media.matchMedia('(max-width: 600px)');
                this.tabletQuery = media.matchMedia(environment.tabletSize);
                this._mobileQueryListener = () => changeDetectorRef.detectChanges();
                this.tabletQuery.addListener(this._mobileQueryListener);

              }

  ngOnInit(): void {

    this.authSubcription = this._auth.getFollowingSubject().subscribe(
      a => this.following = a
    )

    this.followingSubscription = this._store.select('followingsList').subscribe(
      following => {

        const list = following.followings.filter(f => f)

        list.forEach( address => {
          this.selectorSubscription = this._store.select(ValidatorSelectors.selectValidator, address)
          .pipe(
            map(
              result => {
                return result[0]
              }
            )
          )
          .subscribe(
            res => {
              if (!!res) {
                this.following.push(res)
                this._auth.setFollowingSubject(this.following)
              }
            }
          )
        });

      }
    )


    // this.authSubcription = this.store.select('auth').subscribe(
    //   state => {
    //     // console.log(state)
    //     if (!!state.user) {
    //       console.log(state.user.id)
    //       this.store.dispatch(new FollowingActions.LoadFollowingStart(state.user.id));
    //     } else {
    //       console.log('empty')
    //     }
    //   }
    // )
  }



  ngOnDestroy(): void{
    this.tabletQuery.removeListener(this._mobileQueryListener);

    if (this.followingSubscription) {
      this.followingSubscription.unsubscribe();
    }

    if (this.selectorSubscription) {
      this.selectorSubscription.unsubscribe();
    }

    if (this.authSubcription) {
      this.authSubcription.unsubscribe();
    }

  }

}
