import { Action } from '@ngrx/store';
import { Validator } from '../../models/validator.model';



// define types
export enum ValidatorActionTypes {
    LOAD_VALIDATORS_START = '[Validators] Loading Start',
    LOAD_VALIDATORS_SUCCESS = '[Validators] Loading Success',
    LOAD_VALIDATORS_FAIL = '[Validators] Loading Fail',
    FETCH_VALIDATOR_START = '[Validators] Fetch Validator Start',
    FETCH_VALIDATOR_SUCCESS = '[Validators] Fetch Validator Success',
    FETCH_VALIDATOR_FAIL = '[Validators] Fetch Validator Fail',

}



// classes
export class LoadValidatorsStart implements Action {
    readonly type = ValidatorActionTypes.LOAD_VALIDATORS_START;

    constructor() {}
}


export class LoadValidatorsSuccess implements Action {
    readonly type = ValidatorActionTypes.LOAD_VALIDATORS_SUCCESS;
    
    constructor(public payload: Validator[]) {}
}


export class LoadValidatorsFailed implements Action {
    readonly type = ValidatorActionTypes.LOAD_VALIDATORS_FAIL;
    
    constructor(public payload: string) {}
}





export class FetchValidatorStart implements Action {
    readonly type = ValidatorActionTypes.FETCH_VALIDATOR_START;

    constructor(public payload: string) {}
}


export class FetchValidatorSuccess implements Action {
    readonly type = ValidatorActionTypes.FETCH_VALIDATOR_SUCCESS;
    
    constructor(public payload: Validator) {}
}


export class FetchValidatorFailed implements Action {
    readonly type = ValidatorActionTypes.FETCH_VALIDATOR_FAIL;
    
    constructor(public payload: string) {}
}




// collect classes
export type ValidatorActions =
    | LoadValidatorsStart
    | LoadValidatorsSuccess
    | LoadValidatorsFailed
    | FetchValidatorStart
    | FetchValidatorSuccess
    | FetchValidatorFailed;