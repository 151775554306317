import { Actions, ofType, Effect } from '@ngrx/effects';

import { ValidatorActionTypes } from './validator.actions';
import * as ValidatorActions from './validator.actions';

import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { Profile } from '../../models/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BodyRaw, ValidatorBodyRaw } from '../../models/body-raw.model';
import { Validators, Validator, HrmyValidatorRes } from '../../models/validator.model';



const handleError = (errorRes) => {
    let errorMessage = 'An unknown error occurred!';

    console.log(errorRes)

    // if (!errorRes.error || !errorRes.error.error) {
    //     return of(new FollowingActions.LoadFollowingFail(errorMessage));
    // }

    // switch (errorRes.error.error.message) {
    //     case 'EMAIL_EXISTS':
    //         errorMessage = 'This email exists already';
    //         break;
    //     case 'EMAIL_NOT_FOUND':
    //         errorMessage = 'There is no user record corresponding to this identifier';
    //         break;
    //     case 'INVALID_PASSWORD':
    //         errorMessage = 'The password is invalid';
    //         break;
    //     }
    // return of(new FollowingActions.LoadFollowingFail(errorMessage));
}


@Injectable()
export class ValidatorEffects {

    @Effect()
    validatorLoad = this.actions$.pipe(
        ofType(ValidatorActionTypes.LOAD_VALIDATORS_START),
        switchMap(
            () => {
                const postBody: BodyRaw = {
                    jsonrpc: "2.0",
                    id: 1,
                    method: "hmyv2_getAllValidatorInformation",
                    params: [
                      0
                    ],
                }

                return this.http.post<{ [key: string]: Validators }>(
                    environment.harmonySRC,
                    postBody,
                    {
                        responseType: 'json',
                    }
                ).pipe(
                    map(responseData => {
                        // console.log(responseData.result)
                        const validatorsArray: Validator[] = [];

                        for (const key in responseData.result) {
                            validatorsArray.push( {...responseData.result[key], id: key});
                        }

                        // return validatorsArray;
                        return new ValidatorActions.LoadValidatorsSuccess( validatorsArray );
                    }),
                    catchError( errorRespnse => {
                        // Send to analytics server
                        return throwError( errorRespnse );
                    })
                );
            }
        )
    );



    @Effect({dispatch: false})
    validatorSuccess = this.actions$.pipe(
        ofType(ValidatorActionTypes.LOAD_VALIDATORS_SUCCESS),
        tap(
            (authData: ValidatorActions.LoadValidatorsSuccess) => {
                // console.log(authData.payload)

                // this.router.navigate(['user', authData.payload]);
                // this.router.navigate(['/']);
                // console.log(authData.payload.userId)
            }
        )
    );




    @Effect()
    singleLoad = this.actions$.pipe(
        ofType(ValidatorActionTypes.FETCH_VALIDATOR_START),
        switchMap(
            (action: { payload: string, type: string }) => {
                // console.log(action.payload)
                const postBody: ValidatorBodyRaw = {
                    jsonrpc: "2.0",
                    id: 1,
                    method: "hmyv2_getValidatorInformation",
                    params: [
                        action.payload
                    ],
                }

                return this.http.post<HrmyValidatorRes>(
                    environment.harmonySRC,
                    postBody,
                    {
                        responseType: 'json',
                    }
                ).pipe(
                    map(
                        validator => {
                            if (validator.result) {
                                return new ValidatorActions.FetchValidatorSuccess(validator.result);
                            } else {
                                return new ValidatorActions.FetchValidatorFailed(validator.error.message)
                            }
                        }
                    ),
                    // catchError(
                    //     message => of(new ValidatorActions.FetchValidatorFailed(message))
                    // )
                );
            }
        ),
    );


    @Effect({dispatch: false})
    singleFailed = this.actions$.pipe(
        ofType(ValidatorActionTypes.FETCH_VALIDATOR_FAIL),
        tap(
            // () => console.log('eooooorrororo')
            () => this.router.navigate(['/', '404'])
        )
    );



    constructor(private actions$: Actions,
        private http: HttpClient,
        private router: Router) {}

}