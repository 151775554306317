import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { HomePageComponent } from './feature/home-page/home-page.component';
import { AuthComponent } from './feature/auth/auth.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MessagePageComponent } from './feature/message-page/message-page.component';
import { UserModule } from './feature/user/user.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import * as fromApp from './shared/states/app.reducer';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './shared/states/auth/auth.effects';
import { FollowingEffects } from './shared/states/following/following.effects';
import { ValidatorModule } from './feature/validator/validator.module';
import { NotFoundPageComponent } from './feature/not-found-page/not-found-page.component';
import { ValidatorEffects } from './shared/states/validator/validator.effects';
import { SearchModule } from './feature/search/search.module';
import { EpochModule } from './feature/epoch/epoch.module';
import { RealtimePriceModule } from './feature/realtime-price/realtime-price.module';
import { TotalStakingModule } from './feature/total-staking/total-staking.module';
import { ServiceWorkerModule } from '@angular/service-worker';



@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AuthComponent,
    MessagePageComponent,
    NotFoundPageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(fromApp.appReducer),
    EffectsModule.forRoot([AuthEffects, FollowingEffects, ValidatorEffects]),
    StoreDevtoolsModule.instrument( {logOnly: environment.production} ),
    SharedModule,
    CoreModule,
    UserModule,
    ValidatorModule,
    SearchModule,
    EpochModule,
    RealtimePriceModule,
    TotalStakingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
      // registrationStrategy: 'registerImmediately'
    }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
