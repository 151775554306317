import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpochComponent } from './epoch.component';
import { SharedModule } from 'src/app/shared/shared.module';


const COMPONENTS = [
  EpochComponent
]


@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: COMPONENTS

})
export class EpochModule { }
