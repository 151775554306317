import { Injectable } from '@angular/core';
import { Subject, throwError, Observable, interval, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, flatMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';


@Injectable({
  providedIn: 'root'
})
export class PriceService {

  headers = new HttpHeaders();
  
  priceTickerHolder: number = 0;
  private priceTickerCtrl = new BehaviorSubject(this.priceTickerHolder);

  setPriceTickerSubject(value: number) {
    this.priceTickerCtrl.next(value)
  }

  getPriceTickerSubject() {
    return this.priceTickerCtrl.asObservable();
  }
  
  
  
  error = new Subject<string>();
  subject$ = webSocket('wss://ws.s0.t.hmny.io');


  constructor(private http: HttpClient) { }


  getRealtimePrice() {
    return webSocket(environment.binanceWS);
  }


  getPrice() {
    // let searchParams = new HttpParams();
    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    return interval(10000).pipe(
      flatMap(
        () => {
          return this.http.get<{symbol: string, price: string}>(
            environment.binanceAPI,
            {
              responseType: 'json',
              headers: this.headers
            }
          ).pipe(
            map(responseData => {
              return responseData;
            }),
            catchError( errorRespnse => {
              // Send to analytics server
              return throwError( errorRespnse );
            })
          );
        }
      )
    )
  }

  
}
