import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Validator, Validators, TableData, StakingTransactions, SingleValidator } from '../../models/validator.model';
import { map, catchError, take, tap } from 'rxjs/operators';
import { BodyRaw, StakingHistoryBodyRaw, ValidatorBodyRaw } from '../../models/body-raw.model';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  error = new Subject<string>();
  searchOption: TableData[] = [];
  headers = new HttpHeaders();
  // public validatorsData: Validator[];
  public validatorsData: TableData[];



  constructor(private http: HttpClient) { }

  getValidatorAvatar(address: string) {
    return environment.harmonyAvatarsSRC + address + '/avatar'
    // return res.status === 404 ? '../../../assets/images/hmny-logo-avatar.png' : environment.harmonyAvatarsSRC + address + '/avatar'

    // return this.http.get(
    //   environment.harmonyAvatarsSRC + address + '/avatar',
    // )
  }

  getAllValidatorInformation() {
    
    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: BodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getAllValidatorInformation",
      params: [
        0
      ],
    }
  
    return this.http.post<{ [key: string]: Validators }>(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(responseData => {
        const validatorsArray: Validator[] = [];
        for (const key in responseData.result) {
          validatorsArray.push( {...responseData.result[key], id: key});
        }
        return validatorsArray;
      }),
      catchError( errorRespnse => {
        // Send to analytics server
        return throwError( errorRespnse );
      })
    );
  }


  getStakingTransactionsHistory(address: string) {

    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: StakingHistoryBodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getStakingTransactionsHistory",
      params: [
        {
          "address": address,
          "pageIndex": 0,
          "pageSize": 200,
          "fullTx": true,
          "txType": "ALL",
          "order": "DESC"
        }
      ],
    }

    return this.http.post<StakingTransactions>(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map( response => {
        return response.result['staking_transactions'];
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );
  }

  getValidatorInformation(address: string) {

    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Content-Type', 'application/json');
    this.headers.set('Accept', 'application/json');

    const postBody: ValidatorBodyRaw = {
      jsonrpc: "2.0",
      id: 1,
      method: "hmyv2_getValidatorInformation",
      params: [ address ],
    }


    return this.http.post<SingleValidator>(
      environment.harmonySRC,
      postBody,
      {
        responseType: 'json',
        headers: this.headers
      }
    ).pipe(
      map(response => {
        return response.result;
      }),
      catchError( errorRespnse => {
        return throwError( errorRespnse );
      })
    );
  }


  filteredListOptions() {
    let validators = this.validatorsData;
    // console.log(this.validatorsData);

    let filteredValidatorsList = [];

    for (let validator of validators) {
      for (let options of this.searchOption) {
        if (options.name === validator.name) {
          filteredValidatorsList.push(validator);
        }
      }
    }

    // console.log(filteredValidatorsList);
    
    // passes found result to this.validator variable in validator.ts
    return filteredValidatorsList;
  }


  generateStakeWeight(validatorTotalStake: number, networkTotalStake: number): string {
    return ((validatorTotalStake / networkTotalStake) * 100).toString();
  }

}
