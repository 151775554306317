import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStore } from '../../states/app.reducer';
import * as AuthActions from '../../states/auth/auth.actions';
import { Validator } from '../../models/validator.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenExpirationTimer: any;

  constructor(private store: Store<AppStore>) { }


  setLogoutTimer(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout( () => {
      this.store.dispatch(new AuthActions.Logout());
    }, expirationDuration);
  }

  clearLogoutTimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
      this.tokenExpirationTimer = null;
    }
  }

  clearFollowingList() {
    this.setFollowingSubject([])
  }


  followingHolder: Validator[] = [];

  private followingCtrl = new BehaviorSubject(this.followingHolder);

  setFollowingSubject(validator: Validator[]) {
    this.followingCtrl.next(validator);
  }

  getFollowingSubject() {
    return this.followingCtrl.asObservable();
  }
  

  // signup(email: string, password: string) {
  //   return this.http.post<AuthResponseData>(
  //     'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + environment.firebaseAPIKey,
  //     {
  //       email: email,
  //       password: password,
  //       returnSecureToken: true
  //     }
  //   ).pipe(
  //     catchError(this.handleError),
  //     tap(resData => {
  //       this.handleAuthentication(
  //         resData.email,
  //         resData.localId,
  //         resData.idToken,
  //         +resData.expiresIn
  //       );

  //       // after handle authentication, add user data on DB storage
  //       this.userService.addUser(
  //         resData.localId
  //       );
        
  //     })
  //   );
  // }


  // login(email: string, password: string) {
    
  //   return this.http.post<AuthResponseData>(
  //     'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + environment.firebaseAPIKey,
  //     {
  //       email: email,
  //       password: password,
  //       returnSecureToken: true
  //     }
  //   ).pipe(
  //     catchError(this.handleError),
  //     tap(resData => {
  //       this.handleAuthentication(
  //         resData.email,
  //         resData.localId,
  //         resData.idToken,
  //         +resData.expiresIn
  //       );
  //     })
  //   );
  // }

}
