import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Profile } from '../../models/user.model';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppStore } from '../../states/app.reducer';
import * as FollowingActions from '../../states/following/following.actions';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  error = new Subject<string>();


  constructor(private http: HttpClient,
              private store: Store<AppStore>,
              private router: Router) { }


  // to create a database record for this user in Realtime Datatabse in firebase
  setUserProfile(uid: string) {

    // console.log(uid)

    const userData: Profile = {
      id: uid,
      following: [''],
      avatar: ''
    }

    return this.http.post<{ name: string }>(
      environment.firebaseDB + 'users.json',
      userData,
      {
        observe: 'response',
        responseType: 'json'
      }
    ).subscribe(
      responseData => {
        // console.log(responseData)
        this.store.dispatch(new FollowingActions.LoadFollowingStart(uid));
        // this.router.navigate(['user', uid]);
      },
      error => {
        // console.log(error)
        this.error.next(error.message);
      }
    )
  }


  flushUserFollowing() {
    this.store.dispatch(new FollowingActions.FlushFollowing());
  }


}
