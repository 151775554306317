<div *ngIf="tabletQuery.matches" class="signing-precentage-mobile">
    <div class='signing-precentage-mobile__precentage-box'
        [style.opacity]="currentEpochSigningPrecentage == 0 ? '.5' : '1'">

        <span [style.width.%]="currentEpochSigningPrecentage"
              class="current-precent"></span>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal threshold'
                 [ngClass]="currentEpochSigningPrecentage >= 98 ? 'performance' : ''">
            </div>
            <div class='levels levels--internal'></div>
            <div class='levels levels--internal'></div>
        </div>

        <div class='levels levels--main'></div>

    </div>
    <div class="container signing-precentage-mobile__precentage-amount"
        [ngClass]="currentEpochSigningPrecentage == 0 ? 'not-elected' : ''">
        
        <h1 class="percent-count"
            [style.opacity]="currentEpochSigningPrecentage >= 98 ? 1 : '.8'">
            
            <span *ngIf="currentEpochSigningPrecentage > 0">
                {{ currentEpochSigningPrecentage | number: '1.2-2' }}%
            </span>
            <span *ngIf="currentEpochSigningPrecentage == 0">
                00.00%
            </span>
        </h1>
        
    </div>
</div>


<div class="signing-percentage-desktop"
     *ngIf="!tabletQuery.matches"
     data-toggle="tooltip"
     data-placement="bottom"
     [title]="percentageTooltip(currentEpochSigningPrecentage)"
     [style.opacity]="currentEpochSigningPrecentage == 0 ? '.5' : '1'">

    <mat-progress-bar mode="determinate"
                      [value]="currentEpochSigningPrecentage">
    </mat-progress-bar>
</div>


<div class="container validator-page" *ngIf="validator; else loadingPlaceholder">

    <div class="validator-page__headline">

        <h1 class="title title-text"
            [ngClass]="validator['epos-status'] === 'currently elected' ? 'elected' : ''">
            
            <i class="fas fa-circle"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                [title]="validator['epos-status'] != 'currently elected' ? 'Currently NOT elected' : 'Elected'"></i>

            <span class="title-holder">
                {{ validator.validator.name }}
                <!-- <span appBookmarkHandler
                        [isAuthenticated]="isAuthenticated"
                        [validatorAddress]="validatorAddress"
                        class="material-icons bookmark-tooltip">
                        
                    turned_in_not
                </span> -->

            </span>
        </h1>


        
        
        <div class="validator-page__percent-pie">

            <mat-tab-group *ngIf="validator"
                            class="categories-wrapper"
                            disablePagination="false"
                            dynamicHeight>
    
                <mat-tab label="Sign Performance" class="categories-wrapper__tab">
                    <div class="categories-wrapper__chart py-3 py-sm-4 categories-wrapper__content-box">
    
                        <canvas baseChart
                                [legend]="false"
                                width="300"
                                height="300"
                                [options]="doughnutChartSignOptions"
                                [plugins]="doughnutChartPlugins"
                                [data]="validator['epos-status'] != 'currently elected' ? 100 : doughnutChartSignData"
                                [labels]="doughnutChartSignLabels"
                                [colors]="validator['epos-status'] != 'currently elected' ? ['#dadce0'] : doughnutChartColors"
                                [chartType]="doughnutChartType">
                        </canvas>

                    </div>


                    <div class="categories-wrapper__legend performance-chart pb-3 pb-sm-0" *ngIf="totalToSign > 0">
                        <div class="legend-headline">
                            <h4 class="legend-headline__title">
                                {{ totalToSign | number: '1.0' }}
                            </h4>
                            <p class="legend-headline__lablel">Total to sign</p>
                        </div>

                        <div class="legend-details">
                            <div class="legend-detail legend-detail--signed">
                                <h6 class="legend-detail__counter">
                                    {{ signedAmount | number: '1.0' }}
                                </h6>
                                <span class="legend-detail__label">Signed</span>
                                <p class="legend-detail__percntage">
                                    {{ signedAmountPercent | number: '2.0-2' }}%
                                </p>
                            </div>

                            <div class="legend-detail legend-detail--not-signed">
                                <h6 class="legend-detail__counter">
                                    {{ notSignedAmount | number: '1.0' }}
                                </h6>
                                <span class="legend-detail__label">Not signed</span>
                                <p class="legend-detail__percntage">
                                    {{ notSignedAmountPercent | number: '2.0-2' }}%
                                </p>
                            </div>
                        </div>

                    </div>

                </mat-tab>
    

    
                <mat-tab label="Stake Weight" class="categories-wrapper__tab">
                    <div class="categories-wrapper__chart py-3 py-sm-4 categories-wrapper__content-box">
    
                        <canvas baseChart
                                [legend]="false"
                                width="300"
                                height="300"
                                [options]="doughnutChartStakeOptions"
                                [plugins]="doughnutChartPlugins"
                                [data]="doughnutChartStakeData"
                                [labels]="doughnutChartStakeLabels"
                                [colors]="doughnutChartColors"
                                [chartType]="doughnutChartType">
                        </canvas>

                    </div>


                    <div class="categories-wrapper__legend delegation-chart pb-3 pb-sm-0" *ngIf="totalStaked > 0">
                        <div class="legend-headline">
                            <h4 class="legend-headline__title">
                                {{ totalStaked | number: '1.0-0' }}
                            </h4>
                            <p class="legend-headline__lablel">Total staked ONE</p>
                        </div>

                        <div class="legend-details">
                            <div class="legend-detail legend-detail--signed">
                                <h6 class="legend-detail__counter">
                                    {{ validatorDelegation | number: '1.0-0' }}
                                </h6>
                                <span class="legend-detail__label">This validator staked</span>
                                <p class="legend-detail__percntage">
                                    {{ validatorAmountPercent | number: '2.0-2' }}%
                                </p>
                            </div>

                        </div>

                    </div>
                </mat-tab>

    
            </mat-tab-group>
        </div>


        <div class="validator-page__general-info mb-3">

            <div class="box-layout row">
                <div class="pill-box my-1">
                    <small class="pill-box__headline">
                        Latest ER
                    </small>

                    <p class="pill-box__text">
                        {{ latestER | number: '1.2-2' }}%
                    </p>

                </div>
    
                <div class="pill-box my-1">
                    <small class="pill-box__headline">
                        Fee
                    </small>
                    <p class="pill-box__text">
                        {{ (validator.validator.rate) * 100 | number: '1.2-2' }}%
                    </p>
                </div>
    
                <div class="pill-box my-1">
                    <small class="pill-box__headline">
                        Max Fee
                    </small>
                    <p class="pill-box__text">
                        {{ (validator.validator['max-rate']) * 100 | number: '1.2-2' }}%
                    </p>
                </div>
    
                <div class="pill-box my-1">
                    <small class="pill-box__headline">
                        Fee change Rate
                    </small>
                    <p class="pill-box__text">
                        {{ (validator.validator['max-change-rate']) * 100 | number: '1.2-2' }}%
                    </p>
                </div>    
            </div>

            <div class="connection my-3">
                <p class="connection__address w-100"
                    appCopyAddressHandler
                    [cdkCopyToClipboard]="validatorAddress">
                    
                    <span class="plain-text">{{ validatorAddress }}</span>
                </p>

                <p class="connection__website">
                    <a [attr.href]="'http://' + validator.validator.website" alt="validator website">
                        <span class="plain-text">{{ validator.validator.website }}</span>
                    </a>    
                </p>

            </div>


            <div class="validator-description row mt-4">
                <div class="validator-description__logo col-1 d-none d-sm-block">
                    <img appImageSrcHandler
                        [src]="generateAvatar(validatorAddress)"
                        default="../../../assets/images/hmny-logo-avatar.png"
                        class="img-bg">
                </div>

                <blockquote class="validator-description__text col-12 col-sm-11">
                    {{ validator.validator.details }}
                </blockquote>
            </div>
        </div>

        <mat-divider></mat-divider>


        <div class="validator-page__delegation-list mb-5"
             [style.height.px]="generateHeight(delegations.length)"
             [ngClass]="[collapseStatus ? 'closed' : 'opened', delegations.length > 10 ? 'long' : '', delegations.length > 25 ? 'more' : '']"
             (click)="onList($event, delegations.length)" #accordionList>

             <h4 class="headline my-4">
                 Delegation history
                 <small class="note d-block" [ngClass]="delegations.length == 0 ? 'not-elected' : ''">
                    <span>&#8226;</span>&nbsp;<i>Today</i>
                </small>        
             </h4>


            <div class="text-center" *ngIf="delegations.length == 0">
                There are <mark>no transactions</mark> yet!
            </div>
            <mat-accordion *ngIf="delegations.length != 0"
                            class="delegation-accordion" multi>

                <mat-expansion-panel
                    class="delegation-accordion__panel"
                    *ngFor="let d of delegations | slice:0:25">

                    <mat-expansion-panel-header
                        class="delegation-accordion__header delegation-accordion__header--in"
                        [ngClass]="isToday(d.timestamp) ? 'today' : ''"
                        *ngIf="d.type === 'Delegate'">

                        <mat-panel-title class="delegation-accordion__title">
                            {{ (d.msg.amount / 1e18) | number: '1.0-0' }}
                        </mat-panel-title>

                        <mat-panel-description class="delegation-accordion__description">
                            <small>{{ d.timestamp | timestampAgo }}</small>
                            <span>Delegated</span>
                        </mat-panel-description>

                    </mat-expansion-panel-header>


                    <mat-expansion-panel-header
                        class="delegation-accordion__header delegation-accordion__header--out"
                        [ngClass]="isToday(d.timestamp) ? 'today' : ''"
                        *ngIf="d.type === 'Undelegate'">

                        <mat-panel-title class="delegation-accordion__title">
                            {{ d.msg.amount / 1e18 | number: '1.0-0' }}
                        </mat-panel-title>

                        <mat-panel-description class="delegation-accordion__description">
                            <small>{{ d.timestamp | timestampAgo }}</small>
                            <span>Undelegated</span>
                        </mat-panel-description>

                    </mat-expansion-panel-header>

              
                    <a [routerLink]="['/delegator', d.msg.delegatorAddress]"
                        class="delegation-accordion__delegator">
                        <mat-icon class="mr-2">insert_link</mat-icon> {{ d.msg.delegatorAddress }}
                    </a>

              
                </mat-expansion-panel>

              </mat-accordion>

              
        </div>    
    
        <mat-divider></mat-divider>

        <div class="validator-page__er-barchart" [ngClass]="tabletQuery.matches ? 'mb-5 pb-5' : 'mb-4'">

            <h4 class="headline mt-4 mb-0">
                Expected return history
                <small class="note d-block" [ngClass]="validator['epos-status'] != 'currently elected' ? 'not-elected' : ''">
                    <span class="fas fa-angle-up"></span>&nbsp;<i>Max APR: {{ (+maxApr.apr * 100) | number: '1.2-2' }}% on Epoch {{ maxApr.epoch }}</i>
                </small>
                <small class="note d-block" [ngClass]="validator['epos-status'] != 'currently elected' ? 'not-elected' : ''">
                    <span class="fas fa-angle-down"></span>&nbsp;<i>Min &nbsp;APR: {{ (+minApr.apr * 100) | number: '1.2-2' }}% on Epoch {{ minApr.epoch }}</i>
                </small>
            </h4>


            <div class="expected-return-chart py-3 py-sm-4">
    
                <div class="not-elected-warning"
                     *ngIf="validator['epos-status'] != 'currently elected'">
                     
                     -- No data for <strong>Not Elected</strong> validator --
                </div>
                

                <canvas baseChart
                        [legend]="false"
                        width="300"
                        height="100"
                        [options]="erChartOptions"
                        [data]="erChartData"
                        [labels]="erChartLabels"
                        [plugins]="erChartPlugins"
                        [colors]="erChartColors"
                        [chartType]="erChartType">
                </canvas>

            </div>

        </div>

    </div>



</div>

<ng-template #loadingPlaceholder>
    Loading
</ng-template>