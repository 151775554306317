import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';
import { SearchModalComponent } from './search-modal/search-modal.component';


const COMPONENTS = [
  SearchBarComponent,
  SearchModalComponent
]


@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: COMPONENTS
})
export class SearchModule { }
