import { ActionReducerMap, createSelector } from '@ngrx/store';

import * as fromValidator from '../states/validator/validator.reducer';
import * as fromFollowing from '../states/following/following.reducer';
import * as fromAuth from '../states/auth/auth.reducer';
import { Validator } from '../models/validator.model';


export interface AppStore {
    validatorsList: fromValidator.State;
    selectedValidator: fromValidator.singleState;
    followingsList: fromFollowing.State;
    auth: fromAuth.State;
}


export const appReducer: ActionReducerMap<AppStore> = {
    validatorsList: fromValidator.validatorReducer,
    selectedValidator: fromValidator.singleReducer,
    followingsList: fromFollowing.followingReducer,
    auth: fromAuth.authReducer,
}

// test
export const checkValidatorsLoaded = (state: AppStore) => state.validatorsList.isUpdated;

// test
export const allValidators = (state: AppStore) => state.validatorsList.validators;

// test
export const selectValidator = createSelector(
    allValidators,
    (allVal: Validator[], address: string) => {
        // console.log(allVal)
        return allVal.filter(
            v => v.validator.address === address
        )
    }
)

