

<div class="symphonie-home" *ngIf="priceTicker > 0; else logoLoading">


  <div id="symphonie-screenshot" class="symphonie-screenshot" #screenshot>
    <img class="symphonie-screenshot__bg" src="../../../assets/images/symphonie_one_screenshot.jpg">
  
  
    <div class="symphonie-screenshot__earth" *ngIf="earthVisibility">
      <div *ngIf="priceTicker > 0"
            class="earth-element"
            [style.box-shadow]="earthShadow"
            [style.width.px]="screenshotScale"
            [style.height.px]="screenshotScale">
  
            <img src="../../../assets/images/symphonie_earth.png">
      </div>
    </div>
  
    <div class="symphonie-screenshot__earth" *ngIf="!earthVisibility">
      <div class="earth-dot"></div>
    </div>
  
  
    <div class="symphonie-screenshot__logo">
    
      <div class="circle-border">
        <div class="circle-core"></div>
      </div>  
  
      <img class="logo-image" src="../../../assets/images/harmony-one-logo.png">
    </div>
  
    <div class="symphonie-screenshot__pirce-ticker" *ngIf="priceTicker > 0">
      <app-realtime-price [price]="priceTicker"></app-realtime-price>
    </div>
  
    <div class="symphonie-screenshot__staking">
      <app-total-staking [totalStaking]="totalStakingValue"></app-total-staking>
    </div>
  </div>

  <div class="symphonie-home__galaxy">

    <div class="stars"></div>

    <div class="symphonie-home__earth-area" *ngIf="earthVisibility">
      <div *ngIf="priceTicker > 0"
            class="earth-element"
            [style.box-shadow]="earthShadow"
            [style.width.px]="earthScale"
            [style.height.px]="earthScale">
      </div>
    </div>

    <div class="symphonie-home__earth-area" *ngIf="!earthVisibility">
      <div class="earth-dot"></div>
    </div>


    <div class="symphonie-home__harmony">

      <div class="symphonie-home__circles symphonie-home__circles--01">
        <img src="../assets/images/symphonie-circle-01.png"
             [style.animation-duration]="circleSpeed(8.2)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--02">
        <img src="../assets/images/symphonie-circle-02.png"
             [style.animation-duration]="circleSpeed(15.4)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--03">
        <img src="../assets/images/symphonie-circle-03.png"
             [style.animation-duration]="circleSpeed(9.6)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--04">
        <img src="../assets/images/symphonie-circle-04.png"
             [style.animation-duration]="circleSpeed(12.8)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--05">
        <img src="../assets/images/symphonie-circle-05.png"
             [style.animation-duration]="circleSpeed(9)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--06">
        <img src="../assets/images/symphonie-circle-06.png"
             [style.animation-duration]="circleSpeed(9.2)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--07">
        <img src="../assets/images/symphonie-circle-07.png"
             [style.animation-duration]="circleSpeed(9.4)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--08">
        <img src="../assets/images/symphonie-circle-08.png"
             [style.animation-duration]="circleSpeed(19.6)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--09">
        <img src="../assets/images/symphonie-circle-09.png"
             [style.animation-duration]="circleSpeed(9.8)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--10">
        <img src="../assets/images/symphonie-circle-10.png"
             [style.animation-duration]="circleSpeed(10)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--11">
        <img src="../assets/images/symphonie-circle-11.png"
             [style.animation-duration]="circleSpeed(28.2)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--12">
        <img src="../assets/images/symphonie-circle-12.png"
             [style.animation-duration]="circleSpeed(31.4)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--13">
        <img src="../assets/images/symphonie-circle-13.png"
             [style.animation-duration]="circleSpeed(30.6)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--14">
        <img src="../assets/images/symphonie-circle-14.png"
             [style.animation-duration]="circleSpeed(34.8)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--15">
        <img src="../assets/images/symphonie-circle-15.png"
             [style.animation-duration]="circleSpeed(55)">
      </div>
    
      <div class="symphonie-home__circles symphonie-home__circles--16">
        <img src="../assets/images/symphonie-circle-16.png"
             [style.animation-duration]="circleSpeed(41.2)">
      </div>
    
      <a target="_blank"
         class="symphonie-home__logo"
         href="https://staking.harmony.one">
        
         <div class="circle-border">
          <div class="circle-core"></div>
        </div>  
      
        <img class="logo-image" src="../../../assets/images/harmony-one-logo.png">
      </a>
    
    </div>

  </div>

  <div class="symphonie-home__information pt-2 pt-md-5">
    <div class="pirce-ticker mt-3 text-left pl-2 ml-md-4" *ngIf="priceTicker > 0">
      <app-realtime-price [price]="priceTicker"></app-realtime-price>
    </div>

    <div class="epoch-number mt-3 text-right pr-2 mr-md-4">
      <app-epoch></app-epoch>
    </div>


    <div class="total-staking mx-0 text-white">
      <app-total-staking [totalStaking]="totalStakingValue"></app-total-staking>

      <span class="total-staking__screenshot material-icons" (click)="getScreenshot()">
        screenshot
      </span>
    
    </div>

  </div>

</div>



<ng-template #logoLoading>

  <div class="symphonie-home symphonie-home--loading">
    <img class="logo-placeholder"
    src="../../../assets/images/symphonie-placeholder-logo.svg">
  </div>

</ng-template>