import { Pipe, PipeTransform } from '@angular/core';
import { TableData } from '../models/validator.model';

@Pipe({
  name: 'validatorSort'
})
export class ValidatorSortPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(value: TableData, type: string): TableData[] {

    if (!Array.isArray(value)) {
      return
    }

    switch (type) { 
      case 'fee': return value.slice().sort( (a, b) => +a['fee'] < +b['fee'] ? -1 : 1 );
      case 'staked': return value.slice().sort( (a, b) => a['totalStake'] > b['totalStake'] ? -1 : 1 );
      case 'return': return value.slice().sort( (a, b) => +a['latestExpectedReturn'] > +b['latestExpectedReturn'] ? -1 : 1 );
			case 'uptime':
        let electedValidators = [];
        value.forEach( validator => {
          if (validator.status === 'Elected') {
            electedValidators.push(validator);
          }
        })
        return electedValidators.sort( (a, b) => +a['uptime30AVG'] > +b['uptime30AVG'] ? -1 : 1 );

      default: throw new Error(`Invalid safe type specified: ${type}`);
		}
  }


}
