import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChange, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { PriceService } from 'src/app/shared/services/price/price.service';
import { Subscription, timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TothemoonSnackbarComponent } from 'src/app/shared/alerts/tothemoon-snackbar/tothemoon-snackbar.component';
import { NetworkService } from 'src/app/shared/services/network/network.service';
import { take, map, switchMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import html2canvas from 'html2canvas';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';



@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {

  priceSubscription: Subscription;
  tickerSubscription: Subscription;
  networkSubscription: Subscription;
  totalStakingSubscription: Subscription;
  realtimePriceSubscription: Subscription;
  intervalSubscription: Subscription

  lastPrice: number;
  // rocketEmoji: string = "&#128640;&#x1f680;";
  // rocketEmoji: string = "\ud83d\ude80";
  rocketEmoji: string = "\ud83d\ude80";
  speedBooster: number = 1;
  circlesSpeedChange;
  earthVisibility: boolean = true;
  priceOrigin: number = 1;
  epochNumber: number = null;
  networkInfoSubscription: Subscription
  totalStakingValue: number;
  earthScale: number = null;
  screenshotScale: number = null;
  earthShadow: string = null;
  priceTicker: number = 0;
  capturedImage;
  viewPopup: boolean = false;

  @ViewChild('screenshot', {static: false, read: ElementRef}) screenshot: ElementRef;



  constructor(private priceService: PriceService,
              private snackBar: MatSnackBar,
              // private msConfig: MatSnackBarConfig,
              private _renderer: Renderer2,
              private networkService: NetworkService) {

                // this.msConfig = new MatSnackBarConfig();
                // this.msConfig.panelClass = ['snackbar-container']
                // this.msConfig.verticalPosition = "bottom";
                // this.msConfig.horizontalPosition = 'center';
                // this.msConfig.duration = 30000;

                // this.tickerSubscription = this.priceService.getPriceTickerSubject().subscribe(
                //   last => {
                //     this.lastPrice = last
                //   }
                // )

                this.priceSubscription = this.priceService.getRealtimePrice().subscribe(
                  data => {
                    this.priceService.setPriceTickerSubject(+data['bids'][0][0]);
                  }
                )
            
              }



  ngOnInit(): void {

    this.realtimePriceSubscription = this.priceService.getPriceTickerSubject().subscribe(
      price => {
        this.priceTicker = price;

        if (price >= this.priceOrigin) {
          this.earthVisibility = false;
        } else {
          this.earthVisibility = true;
        }

        this.generateEarthScale(price);
        this.generateEarthShadow(price);

      }
    )

    this.totalStakingSubscription = this.networkService.getTotalStakingSubject().subscribe(
      value => {
        if (value != null) {
          this.totalStakingValue = value[1]
        } else {
          this.totalStakingValue = 0

        }
      }
    )

    // this.priceSubscription = this.priceService.getPrice().subscribe(
    //   result => {
    //     if (this.lastPrice != 0 && +result.price >= this.lastPrice + (this.lastPrice * .005)) {
    //       clearTimeout(this.circlesSpeedChange);

    //       this.speedBooster = .2

    //       this.circlesSpeedChange = setTimeout( () => {
    //         this.speedBooster = 1
    //       }, 60000)

    //       this.snackBar.openFromComponent(TothemoonSnackbarComponent,
    //         {
    //           duration: 30000,
    //           panelClass: ['tothemoon-snackbar-container'],
    //           verticalPosition: 'bottom',
    //           horizontalPosition: 'center'
    //         }
    //       )
    //     }

    //     if (+result.price >= this.priceOrigin) {
    //       this.earthVisibility = false;
    //     } else {
    //       this.earthVisibility = true;
    //     }
    
    //     this.pirceTicker = result.price;
    //     this.priceService.setPriceTickerSubject(+result.price)
    //   }
    // )


    this.networkSubscription = this.networkService.getEpoch()
    .pipe(
      take(1),
    )
    .subscribe(
      res => this.epochNumber = res
    )

  }


  getScreenshot() {
    this._renderer.setStyle(this.screenshot.nativeElement, 'display', 'block');
    html2canvas(this.screenshot.nativeElement)
    .then( canvas => {
      document.body.appendChild(canvas);
      canvas.style.display = 'none';
      return canvas;
    })
    .then( canvas => {
      const image = canvas.toDataURL('image/png', 1).replace('image/png', 'image/octet-stream');
      const a = document.createElement('a');
      a.setAttribute('download', 'symphonie-screenshot.png');
      a.setAttribute('href', image);
      a.click();
      canvas.remove();
      this._renderer.setStyle(this.screenshot.nativeElement, 'display', 'none');
    })
  }



  circleSpeed(time: number) {
    return time * this.speedBooster + 's';
  }

  // ngOnChanges(changes: SimpleChange) {
  //   console.log(changes.)
  // }

  generateEarthScale(price: number): number {
    if (price >= this.priceOrigin) {
      return 2
      // this.earchScale = 2;
    } else if (price > 0.01 && price < this.priceOrigin) {
      // return 8 / price
      this.earthScale = 8 / price;
      this.screenshotScale = (8 / price) + (8 / price) * .3;

    } else {
      // return 800
      this.earthScale = 800;
      this.screenshotScale = 1040;
    }
  }


  generateEarthShadow(price: number) {
    this.earthShadow = `inset ${1.4 / price}px 0 ${.9 / price}px ${.4 / price}px rgba(0,0,0,0.9), inset -${1 / price}px 0 ${2 / price}px ${1 / price}px rgba(255,255,255,0.16)`
    // return `inset ${1.4 / price}px 0 ${.9 / price}px ${.4 / price}px rgba(0,0,0,0.9), inset -${1 / price}px 0 ${2 / price}px ${1 / price}px rgba(255,255,255,0.16)`
  }





  ngOnDestroy(): void {
    if (this.tickerSubscription) {
      this.tickerSubscription.unsubscribe();
    }

    if (this.priceSubscription) {
      this.priceSubscription.unsubscribe();
    }

    if (this.networkSubscription) {
      this.networkSubscription.unsubscribe();
    }
    
    if (this.totalStakingSubscription) {
      this.totalStakingSubscription.unsubscribe();
    }

    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    if (this.realtimePriceSubscription) {
      this.realtimePriceSubscription.unsubscribe();
    }
  }

}
