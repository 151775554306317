<div class="epoch-state" *ngIf="currentEpoch > 0">

    <div class="epoch-state__current">
        <span class="epoch-lable">
            current<br>epoch
        </span>
        <h1 class="epoch-counter">
            {{ currentEpoch }}
        </h1>
    </div>
    
    <div class="epoch-state__blocks">
        <span class="block-lable">
            Blocks left:
        </span>
        <strong class="block-counter">
            {{ leftBlocks }}
        </strong>
    </div>

    <mat-progress-bar
        class="epoch-state__progress"
        mode="determinate"
        color="warm"
        [value]="epochStatus">
    </mat-progress-bar>
    
    <p class="epoch-state__time-lable">{{ timeLable }}</p>

</div>

