import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-dummy-box',
  templateUrl: './dummy-box.component.html',
  // template: '<div [style.display]="displayMode" [style.border-radius]="dummyCircle ? (dummyHeight / 2) + \'px\' : 0" [ngStyle]="{ \'width\': dummyPrecentWidth ? dummyWidth + \'%\' : dummyWidth + \'px\' }" [style.height.px]="dummyHeight" class="dummy-box-layer"></div>',
  styleUrls: ['./dummy-box.component.scss']
})
export class DummyBoxComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(cd: ChangeDetectorRef,
              public dialog: MatDialog,
              media: MediaMatcher) {

                this.mobileQuery = media.matchMedia('(max-width: 767px)');
                this._mobileQueryListener = () => cd.detectChanges();
                this.mobileQuery.addListener(this._mobileQueryListener);
              }


  @Input() dummyWidth: number;
  @Input() dummyHeight: number;
  @Input() dummyCircle: boolean = false;
  @Input() dummyPrecentWidth: boolean = false;
  @Input() displayMode: string = 'block';
  @Input() cornerRadius: number = 0;

  

  ngOnInit(): void {}

}
