import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ValidatorService } from 'src/app/shared/services/staking/validator.service';
import { Store } from '@ngrx/store';
import { AppStore } from 'src/app/shared/states/app.reducer';
import { MatDialog } from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { skipWhile, take, map, filter, tap, skip } from 'rxjs/operators';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Validator, Delegation, Lifetime, StakingTransactionsResult, EpochAprLiftime } from 'src/app/shared/models/validator.model';
import * as ValidatorSelectors from 'src/app/shared/states/app.reducer';
import { NetworkService } from 'src/app/shared/services/network/network.service';
import { _MatRadioGroupBase } from '@angular/material/radio';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-single-validator',
  templateUrl: './single-validator.component.html',
  styleUrls: ['./single-validator.component.scss']
})
export class SingleValidatorComponent implements OnInit, OnDestroy {

  routeSubscription: Subscription;
  totalStakeSubscription: Subscription;
  authSubcription: Subscription;
  isFetching: boolean = true;
  error = null;
  tabletQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  pagination: any = false;
  dyorPaginatorLenght: number;
  dyorPaginatorSize: number = 12;
  validator: Validator;
  currentEpochSigningPrecentage: number;
  percentageWarning: string = 'Not Elected!';
  pureDeligations: Delegation[] = [];
  totalToSign: number = 0;
  signedAmount: number = 0;
  notSignedAmount: number = 0;
  signedAmountPercent: number = 0;
  notSignedAmountPercent: number = 0;
  totalStaked: number = 0;
  validatorAmountPercent: number = 0;
  otherAmountPercent: number = 0;
  validatorDelegation: number = 0;
  delegations: StakingTransactionsResult[];
  collapseStatus: boolean = true;
  pseudoHover: boolean = false;
  todayStart: number = new Date().setHours(0, 0, 0, 0);
  validatorAddress: string = '';
  latestER: string = '0';
  maxApr: EpochAprLiftime = null;
  minApr: EpochAprLiftime = null;
  highlightTicks: Array<string> = [];
  isAuthenticated: boolean = false;

  doughnutChartSignLabels: Array<string> = ['Signed', 'Not Signed'];
  doughnutChartStakeLabels: Array<string> = ['This validator', 'Total of rest validators'];
  doughnutChartSignData: Array<number> = [];
  doughnutChartStakeData: Array<number> = [];

  doughnutChartType: string = 'doughnut';
  doughnutChartColors: Array<any> = [
    {
      backgroundColor: ['#758796', '#dadce0']
    }
  ];
  doughnutChartSignOptions: any = {
    tooltips: {
      responsive: true,
      maintainAspectRatio: false,
      displayColors: false,
      bodyAlign: 'center',
      callbacks: {
        title: function(tooltipItem, data) {
          // console.log(tooltipItem)
          return data['labels'][tooltipItem[0]['index']];
        },
        beforeLabel: function(tooltipItem, data) {
          return '';
        },
        label: function(tooltipItem, data) {

          if (data['datasets'][0]['data'].length == 1) {
            tooltipItem.bodyAlign = 'center';
            return '¯\\_(ツ)_/¯'
          }

          const ds = tooltipItem.datasetIndex;

          if (ds == 0) {
            // console.log(data)
            const dataset = data['datasets'][0]['data'][tooltipItem['index']];

            if (parseInt(dataset) >= 1000) {
              var blocks = dataset.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
              var blocks = dataset;
            }

            return 'Blocks: ' + blocks ;
    
          }
        },
        afterLable: function(tooltipItem, data) {
          return '';
        }
      }
    }
  }
  doughnutChartStakeOptions: any = {
    tooltips: {
      responsive: true,
      maintainAspectRatio: false,
      displayColors: false,
      callbacks: {
        title: function(tooltipItem, data) {
          // console.log(tooltipItem)
          return data['labels'][tooltipItem[0]['index']];
        },
        beforeLabel: function(tooltipItem, data) {
          return '';
        },
        label: function(tooltipItem, data) {
          // console.log(tooltipItem)
          const ds = tooltipItem.datasetIndex;
          if (ds == 0) {
            // console.log(data)
            const dataset = data['datasets'][0]['data'][tooltipItem['index']];

            if (parseInt(dataset) >= 1000) {
              var blocks = dataset.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
              var blocks = dataset;
            }
    
            return 'ONEs: ' + blocks ;
          }
        },
        afterLable: function(tooltipItem, data) {
          return '';
        }
      }
    }
  }
  doughnutChartPlugins: any = [
    {
      beforeDraw(chart) {
        // console.log(chart)

        // const ctx = chart.ctx;
        // const txt = chart.tooltip._data.datasets[0]['data'][0] + chart.tooltip._data.datasets[0]['data'][1];

        // if (parseInt(txt) >= 1000) {
        //   var num = txt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        // } else {
        //   var num = txt;
        // }

        // // console.log(chart.innerRadius)

        // const sidePadding = 60;
        // const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);


        // ctx.textAlign = 'center';
        // ctx.textBaseline = 'middle';
        // const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        // const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);


        // const stringWidth = ctx.measureText(txt).width;
        // const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

        // const widthRatio = elementWidth / stringWidth;
        // const newFontSize = Math.floor(16 * widthRatio);
        // const elementHeight = chart.innerRadius * 2;

        // const fontSizeToUse = Math.min(newFontSize, elementHeight);

        // ctx.font = fontSizeToUse + 'px Arial';
        // ctx.fillStyle = '#758796';
        
        // ctx.fillText('Total to sign', centerX, centerY - (centerY * .1))
        // ctx.fillText(num, centerX, centerY + (centerY * .1))

      }
    }
  ]


  erChartData: Array<number> = [];

  erChartLabels: Array<number> = [];

  erChartOptions = {
    responsive: true,
    elements: {
      line: {
        borderWidth: .6,
      }
    },
    scales: {
      xAxes: [
        {
          id: 'x-axis-0',
          ticks: {
            fontColor: '#a9a9a9',
            fontSize: '11',
          }
        }
      ],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'right',
          gridLines: {
            color: 'rgba(169, 169, 169, .1)',
          },
          ticks: {
            fontColor: '#c5168c',
            fontSize: '11',
            beginAtZero: true,
            stepSize: 1,
            callback: function(label, index, labels) {
              return label + '%';
            }
          }
        }
      ]
    },
    tooltips: {
      responsive: true,
      maintainAspectRatio: false,
      displayColors: false,
      callbacks: {
        title: function(tooltipItem, data) {
          // console.log(tooltipItem)
          return 'Epoch ' + data['labels'][tooltipItem[0]['index']];
        },
        beforeLabel: function(tooltipItem, data) {
          return '';
        },
        label: function(tooltipItem, data) {
          // console.log(tooltipItem)
          const ds = tooltipItem.datasetIndex;
          if (ds == 0) {
            // console.log(data)
            const dataset = data['datasets'][0]['data'][tooltipItem['index']];
    
            return 'APR: ' + dataset + '%';
          }
        },
        afterLable: function(tooltipItem, data) {
          return '';
        }
      }
    }

  }

  erChartPlugins = [
    {
      // afterDraw(chart) {
      //   console.log(chart.data.labels)
      //   var ctx = chart.ctx;
      //   var xAxis = chart.scales['x-axis-0'];
      //   var yAxis = chart.scales['y-axis-0'];
      //   ctx.save();
      //   ctx.textAlign = 'center';
      //   ctx.font = '8px Arial';


      //   chart.data.labels.forEach( (el, i) => {
      //     console.log(el)
      //     var value = chart.data.datasets[0].data[i];
      //     var x = xAxis.getPixelForValue(el)
      //     ctx.fillStyle = value == 609 ? 'red' : 'blue';
      //     ctx.fillText(value, x, yAxis.bottom + 17)
      //   });
      //   ctx.restore();
      // }

    }
  ]

  erChartColors = [
    {
      backgroundColor: 'rgba(117, 201, 0, .2)',
      borderColor: 'rgba(76, 146, 2, .8)',
      pointBackgroundColor: 'rgba(117, 201, 0, .8)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(76, 146, 2, 1)',
    }
  ]

  erChartType: string = 'line';


  // @ViewChild('accordionList') ac: ElementRef;


  constructor(cd: ChangeDetectorRef,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private networkService: NetworkService,
              private validatorService: ValidatorService,
              private _store: Store<AppStore>,
              media: MediaMatcher) {

                this.tabletQuery = media.matchMedia(environment.tabletSize);
                this._mobileQueryListener = () => cd.detectChanges();
                this.tabletQuery.addListener(this._mobileQueryListener);

                this.authSubcription = this._store.select('auth').subscribe(
                  auth => {
                    if (!!auth.user) {
                      this.isAuthenticated = true
                    }
                  }
                )
            
              }


              
  ngOnInit(): void {

    this.routeSubscription = this.route.data.subscribe(
      data => {
        const er = data.address.validator.lifetime['epoch-apr'] != null ? data.address.validator.lifetime['epoch-apr'] : [];
        // console.log(data.address.transactions)
        this.validatorAddress = data.address.validator.validator.address;
        this.latestER = er.length != 0 ? (+er[er.length - 1].apr * 100).toString() : '0',
        this.validator = data.address.validator;

        // console.log(this.validator)
        this.electedConfig(data.address.validator, data.address.validator['epos-status']);
        // this.delegationStatus(data.address.validator.validator.delegations);
        
        // this.delegations = data.address.transactions;
        this.signPie(data.address.validator.lifetime, data.address.validator['total-delegation']);

        this.prepareDelegationList(data.address.transactions);

        this.erChart(er);

        this.totalStakeSubscription = this.networkService.getStakingNetworkInfo().subscribe(
          res => {
            this.validatorDelegation = (data.address.validator['total-delegation'] / 1e18);
            this.totalStaked = (res['total-staking'] / 1e18);
            // this.generateStakedPercentage(res['total-staking'] / 1e18, data.address.payload['total-delegation'] / 1e18);
            this.stakedPie(Math.ceil(res['total-staking'] / 1e18), Math.ceil(data.address.validator['total-delegation'] / 1e18));
          }
        )
      }
    )

  }

  // ngAfterViewInit() {
  //   console.log(this.ac.nativeElement)
  // }

  generateHeight(counter: number) {
    // console.log(counter)
    if (counter <= 10) {
      return 'auto'
    } else {
      return 48 * 10
    }
  }



  electedConfig(validator: Validator, elected: string) {
    if (elected === 'currently elected') {
      this.currentEpochSigningPrecentage = +validator["current-epoch-performance"]["current-epoch-signing-percent"]["current-epoch-signing-percentage"] * 100;
    } else {
      this.currentEpochSigningPrecentage = 0;
    }
  }

  // delegationStatus(value: Delegation[]) {
  //   this.pureDeligations = value.filter(
  //     el => el.amount > 0 || el.undelegations.length > 0
  //   )
  // }

  generateAvatar(address: string) {
    return this.validatorService.getValidatorAvatar(address);
  }


  prepareDelegationList(delegations: StakingTransactionsResult[]) {
    this.delegations = delegations.filter(
      el => el.type === 'Delegate' || el.type === 'Undelegate'
    )
  }



  onList(e, counter: number) {

    const yOrigin = e.srcElement.clientHeight;
    const xOrigin = e.srcElement.clientWidth / 2;

    if ( e.srcElement.classList.contains('closed') &&
         e.offsetX >= (xOrigin - 15) &&
         e.offsetX <= (xOrigin + 15) &&
         e.offsetY <= yOrigin &&
         e.offsetY >= (yOrigin - 30) ) {

          this.collapseStatus = !this.collapseStatus;


          if (counter > 25) {
            e.srcElement.style.height = (25 * 48) + 'px';
          } else {
            e.srcElement.style.height = (counter * 48) + 110 + 'px';
          }
    }

    if ( e.srcElement.classList.contains('opened') &&
        e.offsetX >= (xOrigin - 15) &&
        e.offsetX <= (xOrigin + 15) &&
        e.offsetY <= yOrigin &&
        e.offsetY >= (yOrigin - 30) ) {

          this.collapseStatus = !this.collapseStatus;

          // console.log(e)
          e.srcElement.style.height = '480px';
    }
  }


  percentageTooltip(percent: number) {
    if (percent == 0) {
      return 'Currently not elected';
    } else {
      return 'Current signing percent: ' + percent.toFixed(2) + '%';
    }
  }


  erChart(list: EpochAprLiftime[]) {
    const amount = [];
    const labels = [];


    if (list.length == 0) {

      labels.push('-');
      this.erChartOptions.scales.yAxes[0].ticks.fontColor = 'rgba(0, 0, 0, .1)';


      this.maxApr = {apr: '-', epoch: -1}
      this.minApr = {apr: '-', epoch: -1}
      this.erChartData = [0];
      this.erChartLabels = labels;

    } else {
      // console.log(list)
      const counter = list.length - 1;

      for(let i = counter; i >= 0; i--) {
        amount.push((+list[i].apr * 100).toFixed(2));
        labels.push(list[i].epoch)
      }

      this.maxApr = list.reduce( (x, i) => ( i.apr > x.apr ? i : x) )
      this.minApr = list.reduce( (x, i) => ( i.apr < x.apr ? i : x) )

      this.erChartData = amount.reverse();
      this.erChartLabels = labels.reverse();
    }


  }


  signPie(lifetime: Lifetime, totalStaked: number) {
    this.totalStaked = totalStaked;
    this.totalToSign = lifetime.blocks['to-sign'];

    if (lifetime.blocks['to-sign'] == 0) {
      this.doughnutChartSignData = [100];
      this.doughnutChartSignLabels = ['No data for Not Elected validator']
    } else {
      this.signedAmount = lifetime.blocks.signed;
      this.doughnutChartSignData[0] = lifetime.blocks.signed;
      this.doughnutChartSignData[1] = lifetime.blocks['to-sign'] - lifetime.blocks.signed;
      this.notSignedAmount = lifetime.blocks['to-sign'] - lifetime.blocks.signed;
      this.generateSignPercentage(lifetime.blocks['to-sign'], lifetime.blocks.signed);  
    }
  }

  isToday(timestamp: number) {
    // console.log(timestamp)

    if (timestamp > (this.todayStart / 1000)) {
      return true
    } else {
      return false
    }
  }


  stakedPie(total: number, staked: number) {
    this.totalStaked = total;
    // this.totalToSign = lifetime.blocks['to-sign'];
    // this.signedAmount = lifetime.blocks.signed;
    this.doughnutChartStakeData[0] = staked;
    this.doughnutChartStakeData[1] = total - staked;
    this.generateStakedPercentage(total, staked);
  }



  generateSignPercentage(total: number, target: number) {
    const rest = total - target;
    this.signedAmountPercent = (target * 100) / total;
    this.notSignedAmountPercent = (rest * 100) / total;
  }

  generateStakedPercentage(total: number, target: number) {
    const rest = total - target;
    this.validatorAmountPercent = (target * 100) / total;
    this.otherAmountPercent = (rest * 100) / total;
  }


  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.authSubcription) {
      this.authSubcription.unsubscribe();
    }

    this.tabletQuery.removeListener(this._mobileQueryListener);

  }

}
