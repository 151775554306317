
<div class="validator-modal">
    <div class="validator-modal__headline">
        <span class="badge" [ngClass]="data.status == 'Elected' ? 'badge-success' : 'badge-secondary'">{{ data.status }}</span>
        <!-- <span class="material-icons">turned_in_not</span> -->
        <!-- <span appBookmarkHandler
             [validatorAddress]="data.address"
             [isAuthenticated]="isAuthenticated"
             (modalStatus)="onNoClick()"
             class="material-icons bookmark-tooltip">
            
            turned_in_not
        </span> -->

    </div>

    <div class="validator-modal__information">
        <h1 class="title">
        <!-- <a [href]="data.website" class="material-icons">
            link
        </a> -->
        {{ data.name }}</h1>
        <mat-divider></mat-divider>

        <form class="summary row">
            <mat-form-field class="summary__field col-12 col-sm-6 pr-sm-0">
                <mat-label>Uptime Average</mat-label>
                <input matInput
                        type="text"
                        class="validator-modal__information"
                        disabled
                        [value]="(data.uptime30AVG | number: '1.2-2') + '%'">
            </mat-form-field>
            
            <mat-form-field class="summary__field col-12 col-sm-6 pl-sm-0">
                <mat-label>Current Signing</mat-label>
                <input *ngIf="data.status === 'Not elected'"
                        matInput
                        class="validator-modal__information"
                        type="text"
                        disabled
                        value="Not elected!">

                <input *ngIf="data.status === 'Elected'"
                        matInput
                        type="text"
                        disabled
                        [value]="(data.currentEpochSigningPercent | number: '1.2-2') + '%'">
            </mat-form-field>


            <mat-form-field class="summary__field col-12 col-sm-6 pr-sm-0">
                <mat-label>Fee</mat-label>
                <input matInput
                        type="text"
                        class="validator-modal__information"
                        disabled
                        [value]="(data.fee | number: '1.2-2') + '%'">
            </mat-form-field>

            <mat-form-field class="summary__field col-12 col-sm-6 pl-sm-0">
                <mat-label>Latest Expected Return</mat-label>
                <input matInput
                        class="validator-modal__information"
                        type="text"
                        disabled
                        [value]="(data.latestExpectedReturn | number: '1.2-2') + '%'">
            </mat-form-field>


            <mat-form-field class="summary__field col-12 col-sm-6 pr-sm-0">
                <mat-label>Max Fee Change</mat-label>
                <input matInput
                        class="validator-modal__information"
                        type="text"
                        disabled
                        [value]="(data.maxFeeChange | number: '1.2-2') + '%'">
            </mat-form-field>

            <mat-form-field class="summary__field col-12 col-sm-6 pl-sm-0">
                <mat-label>Total Staked</mat-label>
                <input matInput
                        class="validator-modal__information"
                        type="text"
                        disabled
                        [value]="data.totalStake | number: '1.0-0'">
            </mat-form-field>

        </form>
          
    </div>

    <div class="validator-modal__footer mt-4">
        <a [routerLink]="['/validator', data.address]"
            mat-button cdkFocusInitial>

            View More
        </a>

        <button mat-button (click)="onNoClick()">
            Close
        </button>
    </div>

</div>


