// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseDB: 'https://symphonie-one-users-default-rtdb.firebaseio.com/',
  firebaseAPIKey: 'AIzaSyBrsAEvDqZ62FyV9n0KHtC65K65wZ-vbUA',
  harmonySRC: 'https://rpc.s0.t.hmny.io',
  harmonySRC0: 'https://a.api.s0.t.hmny.io',
  harmonySRC1: 'https://rpc.s1.t.hmny.io',
  harmonySRC2: 'https://rpc.s2.t.hmny.io',
  harmonySRC3: 'https://rpc.s3.t.hmny.io',
  binanceAPI: 'https://api.binance.com/api/v3/ticker/price?symbol=ONEUSDT',
  binanceWS: 'wss://stream.binance.com:9443/ws/oneusdt@depth20@100ms',
  harmonyAvatarsSRC: 'https://api.stake.hmny.io/networks/mainnet/validators/',
  tabletSize: '(max-width: 767px)',
  mobileSize: '(max-width: 568px)'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
