<form class="search-bar text-center">
    <mat-form-field class="search-bar__full-width">
        <mat-label>Search</mat-label>


        <mat-chip-list #chipList>
            <mat-chip style="margin-top: 0.4em;"
                        *ngFor="let option of options"
                        (removed)="removeOption(option)">

                <span>{{ option.name }}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <input #autoCompleteInput type="text"
                    [placeholder]="options.length == 0 ? 'Validator name/address' : ''"
                    aria-label="Number" matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList">
            
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of autoCompleteList"
                            [value]="option"
                            (onSelectionChange)="filterValidatorList($event)">

                    {{ option.name }}
                </mat-option>
            </mat-autocomplete>
            
        </mat-chip-list>

        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

<!-- 
    <mat-form-field class="example-full-width">
        <mat-label>Telephone</mat-label>
        <span matPrefix>+1 &nbsp;</span>
        <input type="tel" matInput placeholder="555-555-1234">
        <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field> -->
    
</form>