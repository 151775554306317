import { Directive, ElementRef, HostListener, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appCopyAddressHandler]'
})
export class CopyAddressHandlerDirective implements OnInit {

  constructor(private _el: ElementRef,
              private _renderer: Renderer2) { }


  @HostListener('click') onClick() {

    this._renderer.addClass(this._el.nativeElement, 'copied')
      
    setTimeout( () => {
      this._renderer.removeClass(this._el.nativeElement, 'copied')
    }, 1000);
  
  }


  public ngOnInit() {
    this._renderer.addClass(this._el.nativeElement, 'wallet-address')
  }

}
