

<div class="validator-list-page container">
    <h1 class="validator-list-page__title">Validators table</h1>

    <div class="validator-list-page__table" *ngIf="validators">
        <form #form="ngForm" class="form-inline sort-actions">

            <div class="btn-group btn-group-toggle mr-3 sort-actions__general"
                data-toggle="buttons">

                <label class="btn btn-secondary active">
                    <input type="radio"
                            name="options"
                            id="all"
                            value=""
                            [(ngModel)]="sortGeneral"
                            autocomplete="off"
                            checked> All
                </label>

                <label class="btn btn-secondary">
                    <input type="radio"
                            name="options"
                            id="elected"
                            value="elected"
                            [(ngModel)]="sortGeneral"
                            autocomplete="off"> Elected
                </label>
            </div>


            <div class="sort-actions__options">

                <div class="form-check form-check-inline">
                    <input class="form-check-input regular-style"
                            type="radio"
                            name="filterOptions"
                            [(ngModel)]="sortOption"
                            id="uptimeButton"
                            checked
                            value="uptime">

                    <label class="form-check-label btn btn-light"
                            for="uptimeButton">Uptime</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input regular-style"
                            type="radio"
                            name="filterOptions"
                            id="stakedButton"
                            [(ngModel)]="sortOption"
                            value="staked">

                    <label class="form-check-label btn btn-light"
                            for="stakedButton">Total Staked</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input regular-style"
                            type="radio"
                            name="filterOptions"
                            id="erButton"
                            [(ngModel)]="sortOption"
                            value="return">

                    <label class="form-check-label btn btn-light"
                            for="erButton">Highest ER</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input regular-style"
                            type="radio"
                            name="filterOptions"
                            [(ngModel)]="sortOption"
                            id="feeButton"
                            value="fee">

                    <label class="form-check-label btn btn-light"
                            for="feeButton">Lowest Fee</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input regular-style"
                            type="radio"
                            name="filterOptions"
                            [(ngModel)]="sortOption"
                            id="changeButton"
                            value="feechange">

                    <label class="form-check-label btn btn-light"
                            for="changeButton">Lowest Fee Change</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input regular-style"
                            type="radio"
                            name="filterOptions"
                            [(ngModel)]="sortOption"
                            id="maxButton"
                            value="maxfee">

                    <label class="form-check-label btn btn-light"
                            for="maxButton">Lowest Max Fee</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input regular-style"
                            type="radio"
                            name="filterOptions"
                            [(ngModel)]="sortOption"
                            id="currentButton"
                            value="currentSigning">

                    <label class="form-check-label btn btn-light"
                            for="currentButton">Current Signing</label>
                </div>

            </div>

        </form>


        
        <div class="table-swiper" *ngIf="tabletQuery.matches">
            <span class="material-icons" (click)="onTableScroll(-1)">
                arrow_back_ios
            </span>
            <span class="material-icons" (click)="onTableScroll(1)">
                arrow_forward_ios
            </span>
        </div>

        <table #table
                class="table-list table table-striped table-bordered table-hover mb-5">
            
            <thead class="thead-dark">
                <tr #tableRows class="table-list__tr table-list__tr--thead">
                    <th class="table-list__th table-list__th--1st table-list__th--fixed">
                        <span class="material-icons" matTooltip="Follow">
                            book
                        </span>
                    </th>
                    <th class="table-list__th table-list__th--2nd table-list__th--fixed">
                        <span class="material-icons" matTooltip="Status">
                            adjust
                        </span>
                    </th>
                    <th class="table-list__th table-list__th--3rd table-list__th--fixed">
                        Name
                    </th>
                    <th class="table-list__th table-list__th--4th table-list__th--dynamic">
                        Uptime
                    </th>
                    <th class="table-list__th table-list__th--5th table-list__th--dynamic">
                        Total Staked
                    </th>
                    <th class="table-list__th table-list__th--6th table-list__th--dynamic">
                        Latest ER
                    </th>
                    <th class="table-list__th table-list__th--7th table-list__th--dynamic">
                        Fee
                    </th>
                    <th class="table-list__th table-list__th--8th table-list__th--dynamic">
                        Max Fee Change
                    </th>
                    <th class="table-list__th table-list__th--9th table-list__th--dynamic">
                        Max Fee
                    </th>
                    <th class="table-list__th table-list__th--10th table-list__th--dynamic">
                        Current<br>Signing %
                    </th>
                    <th class="table-list__th table-list__th--11th table-list__th--dynamic">
                        <i class="fas fa-wallet" matTooltip="Validator address"></i>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let v of validators | tableSort: form.value"
                    #tableRows
                    class="table-list__tr table-list__tr--tbody">

                    <td class="table-list__td table-list__td--1st table-list__td--fixed">
                        <!-- <span appBookmarkHandler
                              [validatorAddress]="v.address"
                              [isAuthenticated]="isAuthenticated"
                              class="material-icons bookmark-tooltip">
                              
                            turned_in_not
                        </span> -->
                        <span aria-disabled="true"
                                disabled
                                style="color: rgba(0,0,0,0.2)"
                              class="material-icons">
                              
                            turned_in_not
                        </span>

                    </td>
                    <td class="table-list__td table-list__td--2nd table-list__td--fixed"
                        style="width: 30px">
                        <i class="fas fa-circle elected-sign"
                            [ngClass]="v.status == 'Elected' ? 'elected' : ''"
                            [matTooltip]="v.status == 'Elected' ? 'Elected' : 'Not elected'">
                        </i>
                    </td>
                    <td class="table-list__td table-list__td--3rd table-list__td--fixed"
                        style="width: 30px">
                        <a href="javascript:void(0)" (click)="openValidatorModal(v)">
                            {{ v.name }}
                        </a>
                    </td>
                    <td class="table-list__td table-list__td--4th table-list__td--dynamic">
                        {{ v.uptime30AVG | number: '1.2-2' }}%
                    </td>
                    <td class="table-list__td table-list__td--5th table-list__td--dynamic">
                        {{ v.totalStake | number: '1.0-0' }}
                    </td>
                    <td class="table-list__td table-list__td--6th table-list__td--dynamic">
                        {{ v.latestExpectedReturn | number: '1.2-2' }}%
                    </td>
                    <td class="table-list__td table-list__td--7th table-list__td--dynamic">
                        {{ v.fee | number: '1.2-2' }}%
                    </td>
                    <td class="table-list__td table-list__td--8th table-list__td--dynamic">
                        {{ v.maxFeeChange | number: '1.2-2' }}%
                    </td>
                    <td class="table-list__td table-list__td--9th table-list__td--dynamic">
                        {{ v.maxFee | number: '1.2-2' }}%
                    </td>

                    <td *ngIf="v.status != 'Elected'" class="table-list__td table-list__td--10th table-list__td--dynamic">
                        0%
                    </td>
                    <td *ngIf="v.status == 'Elected'" class="table-list__td table-list__td--10th table-list__td--dynamic">
                        {{ v.currentEpochSigningPercent | number: '1.2-2' }}%
                    </td>

                    <td class="table-list__td table-list__td--11th table-list__td--dynamic">
                        <span class="material-icons"
                              appCopyAddressHandler
                              [cdkCopyToClipboard]="v.address">

                            content_copy
                        </span>
                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr #tableRows class="table-list__tr table-list__tr--tfoot">
                    <th class="table-list__th table-list__th--1st table-list__th--fixed">
                        <span class="material-icons" matTooltip="Follow">
                            book
                        </span>
                    </th>
                    <th class="table-list__th table-list__th--2nd table-list__th--fixed">
                        <span class="material-icons" matTooltip="Status">
                            adjust
                        </span>
                    </th>
                    <th class="table-list__th table-list__th--3rd table-list__th--fixed">
                        Name
                    </th>
                    <th class="table-list__th table-list__th--4th table-list__th--dynamic">
                        Uptime
                    </th>
                    <th class="table-list__th table-list__th--5th table-list__th--dynamic">
                        Total Staked
                    </th>
                    <th class="table-list__th table-list__th--6th table-list__th--dynamic">
                        Latest ER
                    </th>
                    <th class="table-list__th table-list__th--7th table-list__th--dynamic">
                        Fee
                    </th>
                    <th class="table-list__th table-list__th--8th table-list__th--dynamic">
                        Max Fee Change
                    </th>
                    <th class="table-list__th table-list__th--9th table-list__th--dynamic">
                        Max Fee
                    </th>
                    <th class="table-list__th table-list__th--10th table-list__th--dynamic">
                        Current<br>Signing %
                    </th>
                    <th class="table-list__th table-list__th--11th table-list__th--dynamic">
                        <i class="fas fa-wallet" matTooltip="Validator address"></i>
                    </th>
                </tr>
            </tfoot>
    
        </table>
    </div>

</div>